import React from "react";
import RulesAndConditions from "../../components/rulesAndConditions";

function RulesAndConditionsPage(props) {
  return (
    <div className="page-container">
      <RulesAndConditions />
    </div>
  );
}

export default RulesAndConditionsPage;
