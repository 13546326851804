import React, { useEffect, useRef, useState } from "react";

import "./newcusomselect.css";
import { useTranslation } from "react-i18next";

// Icon component
const Icon = ({ isOpen }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="18"
      height="18"
      stroke="#222"
      strokeWidth="1.5"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={isOpen ? "translate" : ""}
    >
      <polyline points="6 9 12 15 18 9"></polyline>
    </svg>
  );
};

// CloseIcon component
const CloseIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="14"
      height="14"
      stroke="#fff"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  );
};

// CustomSelect component
const NewCustomSelect = ({
  placeHolder,
  options,
  isMulti,
  isSearchable,
  onChange,
  align,
  transportId,
  label,
  mean,
}) => {
  // State variables using React hooks
  const [showMenu, setShowMenu] = useState(false); // Controls the visibility of the dropdown menu
  const [selectedValue, setSelectedValue] = useState(null); // Stores the selected value(s)
  const [searchValue, setSearchValue] = useState(""); // Stores the value entered in the search input
  const searchRef = useRef(); // Reference to the search input element
  const inputRef = useRef(); // Reference to the custom select input element
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    setSearchValue("");
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  });

  const handleInputClick = (e) => {
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    if (i18n.language === "en") {
      return mean?.type?.value;
    } else {
      return mean?.type?.frenchValue;
    }
  };

  const removeOption = (option) => {
    return selectedValue.filter((o) => o.value !== option.value);
  };

  const onTagRemove = (e, option) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const onItemClick = (option, id) => {
    setSelectedValue(option);
    onChange(option, id);
  };

  const isSelected = (option) => {
    if (isMulti) {
      return selectedValue.filter((o) => o.value === option.value).length > 0;
    }

    if (!selectedValue) {
      return false;
    }

    return selectedValue.value === option.value;
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter(
      (option) =>
        option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  return (
    <div className="select-container">
      <div ref={inputRef} onClick={handleInputClick} className="dropdown-input">
        <div
          className={showMenu ? "selected-value focused" : "selected-value "}
        >
          <div className="select-label">{label}</div>
          <span
            style={{
              position: "absolute",
              left: "20px",
              top: "50%",
              transform: "translateY(-50%)",
              textTransform: "capitalize",
            }}
          >
            {getDisplay()}
          </span>
          {!showMenu && (
            <button className="delete-btn" type="button">
              <img src=" dropdown-arrow-triangle.png" alt="dropdown icon" />
            </button>
          )}
        </div>
      </div>

      {showMenu && (
        <div className="options-container">
          <ul>
            {options.map((option) => (
              <li
                onClick={() => onItemClick(option, transportId)}
                key={option.value}
                className={`dropdown-item`}
              >
                {i18n.language === "en" ? option.value : option.frenchValue}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default NewCustomSelect;
