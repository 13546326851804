import { useNavigate } from "react-router";
import React, { useEffect, useRef, useState } from "react";
import "./homepage.css";
import { useTranslation } from "react-i18next";
import Button from "../../components/button"

const HomePage = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* Animate when scroll past */
  const boxesContainer = useRef(null)
  const [activeBoxes, setActiveBoxes] = useState(false);
  let boxesContainerMiddle
  useEffect(() => {
    boxesContainerMiddle = boxesContainer.current.offsetTop + (boxesContainer.current.clientHeight / 2);
  }, [boxesContainer]);

  const pricingTableHomepage = useRef(null)
  const [activePricingTable, setActivePricingTable] = useState(false);
  let pricingTableHomePageBottom
  useEffect(() => {
    pricingTableHomePageBottom = pricingTableHomepage.current.offsetTop + (pricingTableHomepage.current.clientHeight / 2);
  }, [pricingTableHomepage]);

  const slideStatContainer = useRef(null)
  const [activeSlideStat, setActiveSlideStat] = useState(false);
  let slideStatContainerMiddle
  useEffect(() => {
    slideStatContainerMiddle = slideStatContainer.current.offsetTop + 4 * (slideStatContainer.current.clientHeight / 5);
  }, [slideStatContainer]);

  /* Animate every section with fade in and translate */
  const homepageContainer = useRef(null)
  let sectionsContainer = []
  let sectionsObjects = []
  useEffect(() => {
    sectionsContainer = homepageContainer.current.querySelectorAll(".homepage > div")
    sectionsContainer.forEach(element => {
      sectionsObjects.push({ class: element.classList[0], position: element.offsetTop, third: element.clientHeight / 3 })
    });
    homepageContainer.current.querySelector(`.${sectionsObjects[0].class}`).classList.add("visibleSection")
    homepageContainer.current.querySelector(`.${sectionsObjects[0].class}.small-screen`).classList.add("visibleSection")
  }, [homepageContainer])

  // floaty blobs animations
  useEffect(() => {
    let blobs = homepageContainer.current.querySelectorAll(".homepage-blob")
    blobs.forEach(blob => {
      blob.addEventListener('animationend', () => {
        blob.classList.remove('animated');
      });
      blob.addEventListener('mouseover', () => {
        blob.style.animationIterationCount = "infinite"
        blob.classList.add('animated')
      })
      blob.addEventListener('mouseleave', () => {
        blob.style.animationIterationCount = "1"
      })
    })
  }, [homepageContainer])

  window.addEventListener("scroll", () => {
    let currentScrollBottom = window.scrollY + document.documentElement.clientHeight;

    //Animations of sections (fade in / translateY)
    sectionsObjects.forEach(section => {
      if (homepageContainer.current != null && currentScrollBottom >= section.position + section.third) {
        homepageContainer.current.querySelector(`.${section.class}`).classList.add("visibleSection")
      }
    })

    //Animations of elements in every sections
    if (currentScrollBottom >= boxesContainerMiddle) {
      setActiveBoxes(true)
    }

    if (currentScrollBottom >= pricingTableHomePageBottom) {
      setActivePricingTable(true)
    }

    if (currentScrollBottom >= slideStatContainerMiddle) {
      setActiveSlideStat(true)
    }
  })

  return (
    <div ref={homepageContainer} className="homepage">
      <div className="welcome-vegtax-container desktop">
        <img className="welcome-avatar" src="/avatarOfficeVers2 - Copy.png" alt="/avatarOfficeVers2 - Copy.png" />
        <div className="welcome-text-container">
          <h4 className="welcome-title title">
            {t("homepage.welcome.titleStart")}
            <span className="logo-veg medium-logo-span">VEG</span>
            <span className="logo-tax medium-logo-span">TAX.</span>
            {t("homepage.welcome.titleEnd")} <br />
            {t("homepage.welcome.subtitle")}
          </h4>
          <p className="welcome-paragraph">{t("homepage.welcome.textOurMission")}</p>
          <Button text={t("how.statsSection.letsGo")} onClick={() => navigate("/tax-refund")}></Button>
        </div>
        <img className="welcome-section-blob-dark welcome-blob homepage-blob" src="/blob-homePage-Welcome-dark.svg" alt="blob-homePage-Welcome-dark.svg" />
        <img className="welcome-section-blob-light welcome-blob homepage-blob" src="/blob-homePage-Welcome-light.svg" alt="blob-homePage-Welcome-light.svg" />
      </div>

      <div className="welcome-vegtax-container small-screen">
        <h4 className="welcome-title title">
          {t("homepage.welcome.titleStart")}
          <span className="logo-veg medium-logo-span">VEG</span>
          <span className="logo-tax medium-logo-span">TAX.</span>
          {t("homepage.welcome.titleEnd")} <br />
          {t("homepage.welcome.subtitle")}
        </h4>
        <div className="welcome-text-container">
          <div className="welcome-button-text-container">
            <p className="welcome-paragraph">{t("homepage.welcome.textOurMission")}</p>
            <Button text={t("how.statsSection.letsGo")} onClick={() => navigate("/tax-refund")}></Button>
          </div>
        </div>
        <img className="welcome-avatar" src="/avatarOfficeVers2 - Copy.png" alt="/avatarOfficeVers2 - Copy.png" />
        <img className="welcome-section-blob-dark welcome-blob homepage-blob" src="/blob-homePage-Welcome-dark.svg" alt="blob-homePage-Welcome-dark.svg" />
        <img className="welcome-section-blob-light welcome-blob homepage-blob" src="/blob-homePage-Welcome-light.svg" alt="blob-homePage-Welcome-light.svg" />
      </div>

      <div ref={boxesContainer} className="how-container scroll-snap-section">
        <h4 className="how-title title">{t("homepage.howChooseVegtax.title")}</h4>
        <div className={"boxes-container how " + (activeBoxes ? "activeBoxes" : "")}>
          <div className="step-box how-step-box1">
            <img className="step-box-icon" src="/howItWorks-eligibility-icon.svg" alt="howItWorks-eligibility-icon.svg" />
            <div className="step-box-title-container">
              <p className="step-box-title">
                {t("how.stepsSection.eligibilityTitleStart")}
                {t("how.stepsSection.eligibilityTitleLink")}
                {t("how.stepsSection.eligibilityTitleEnd")}
              </p>
            </div>
          </div>

          <div className="boxes-link how-boxes-link1"></div>

          <div className="step-box how-step-box2">
            <img className="step-box-icon" src="/howItWorks-submit-icon.svg" alt="howItWorks-submit-icon.svg" />
            <div className="step-box-title-container">
              <p className="step-box-title">{t("how.stepsSection.submitTitle")}</p>
            </div>
          </div>

          <div className="boxes-link how-boxes-link2"></div>

          <div className="step-box how-step-box3">
            <img className="step-box-icon" src="/howItWorks-pay-icon.svg" alt="howItWorks-pay-icon.svg" />
            <div className="step-box-title-container">
              <p className="step-box-title">{t("how.stepsSection.payTitle")}</p>
            </div>
          </div>
        </div>
        <Button className="how-button" text={t("homepage.howChooseVegtax.learn")} onClick={() => navigate("/howitworks")}></Button>
        <img className="how-section-blob-dark welcome-blob homepage-blob" src="/blob-homePage-how-dark.svg" alt="blob-homePage-how-dark.svg" />
        <img className="how-section-blob-light welcome-blob homepage-blob" src="/blob-homePage-how-light.svg" alt="blob-homePage-how-light.svg" />
      </div>

      <div ref={slideStatContainer} className="homepage-why-choose-container scroll-snap-section">
        <img className="why-blob-dark why-blob homepage-blob" src="/blob-homePage-whyChoose-dark.svg" alt="blob-homePage-whyChoose-dark.svg" />
        <img className="why-blob-light-right why-blob homepage-blob" src="/blob-homePage-whyChoose-light-right.svg" alt="blob-homePage-whyChoose-light-right.svg" />
        <img className="why-avatar" src="/POSE_TASNA_COMP.png" alt="POSE_TASNA_COMP.png" />

        <h4 className="homepage-why-title title">
          {t("homepage.whyChooseVegtax.title")}
          <span className="logo-veg medium-logo-span">VEG</span>
          <span className="logo-tax medium-logo-span">TAX. </span>
          ?
        </h4>

        <div className="why-choose-content-container">
          <div className="why-timeSave-container why-list-block">
            <div className="list-lineDown"></div>
            <div className="line-point-container">
              <div className="list-line"></div>
              <div className="list-point"></div>
            </div>
            <div className="timeSave-text-container">
              <div className="why-choose-list-title">{t("homepage.whyChooseVegtax.timeSaveTitle")}</div>
              <div className="why-choose-list-text">{t("homepage.whyChooseVegtax.timeSaveText")}</div>
            </div>
          </div>
          <div className="why-accessibility-container why-list-block">
            <div className="list-lineDown"></div>
            <div className="line-point-container">
              <div className="list-line"></div>
              <div className="list-point"></div>
            </div>
            <div className="accessibility-text-container">
              <div className="why-choose-list-title">{t("homepage.whyChooseVegtax.accessibilityTitle")}</div>
              <div className="why-choose-list-text">{t("homepage.whyChooseVegtax.accessibilityText")}</div>
            </div>
          </div>
          <div className="why-expertise-container why-list-block">
            <div className="line-point-container">
              <div className="list-line"></div>
              <div className="list-point"></div>
            </div>
            <div className="expertise-text-container">
              <div className="why-choose-list-title">{t("homepage.whyChooseVegtax.expertiseTitle")}</div>
              <div className="why-choose-list-text">{t("homepage.whyChooseVegtax.expertiseText")}</div>
            </div>
          </div>
        </div>

        <div className={"whyChoose-experienceStat-container " + (activeSlideStat ? "activeSlideStat" : "")}>
          <div className="experienceStat-content-container">
            <p className="experienceStat-description">{t("homepage.whyChooseVegtax.experienceStat.description")}</p>
            <div className="experienceStat-pill-container">
              <div className="experienceStat-pill">
                <p className="experienceStat-number">+25</p>
                <p className="experienceStat-stat">{t("homepage.whyChooseVegtax.experienceStat.years")}</p>
              </div>
              <div className="experienceStat-pill">
                <p className="experienceStat-number">+1200</p>
                <p className="experienceStat-stat">{t("homepage.whyChooseVegtax.experienceStat.clients")}</p>
              </div>
              <div className="experienceStat-pill">
                <p className="experienceStat-number">10</p>
                <p className="experienceStat-stat">{t("homepage.whyChooseVegtax.experienceStat.associates")}</p>
              </div>
            </div>
          </div>
          <div className="experienceStat-logo-container">
            <a href="https://www.vegconsulting.ch/qui-sommes-nous/" target="_blank">
              <img className="experienceStat-logo" src="/SILVER_SYMBOL.png" alt="/SILVER_SYMBOL.png"></img>
            </a>
          </div>
        </div>
        <img className="why-blob-light-left why-stat-blob homepage-blob" src="/blob-homePage-whyChoose-light-left.svg" alt="blob-homePage-whyChoose-light-left.svg" />
      </div>

      <div ref={pricingTableHomepage} className="homepage-pricing-container scroll-snap-section">
        <h4 className="homepage-pricing-title title">{t("homepage.pricing.title")}</h4>

        <div className="homepage-pricing-table-container">
          <div className={"homepage-pricing-table-border " + (activePricingTable ? 'activePricingTable' : "")}>
            <img className="homoepage-pricing-col1 homoepage-pricing-row1 profile-icon" src="/student-icon.svg" alt="/student-icon.svg" />
            <img className="homoepage-pricing-col3 homoepage-pricing-row1 profile-icon" src="/simple-person-icon.svg" alt="/simple-person-icon.svg" />
            <img className="homoepage-pricing-col5 homoepage-pricing-row1 profile-icon" src="/couple-icon.svg" alt="/couple-icon.svg" />

            <div className="homoepage-pricing-col1 homepage-pricing-row2">{t("conceptPage.pricing.student")}</div>
            <div className="homoepage-pricing-col3 homepage-pricing-row2">{t("conceptPage.pricing.simple")}</div>
            <div className="homoepage-pricing-col5 homepage-pricing-row2">{t("conceptPage.pricing.couple")}</div>

            <div className="homoepage-pricing-col1 homepage-pricing-row3">50 CHF</div>
            <div className="homoepage-pricing-col3 homepage-pricing-row3">{t("from")} 80 CHF</div>
            <div className="homoepage-pricing-col5 homepage-pricing-row3">{t("from")} 100 CHF</div>

            <div className="line" style={{ gridColumn: "2", gridRow: "1 / span 3" }}></div>


            <div className="line" style={{ gridColumn: "4", gridRow: "1 / span 3" }}></div>

          </div>
        </div>

        <Button className="why-button" text={t("homepage.howChooseVegtax.learn")} onClick={() => navigate("/ourconcept")}></Button>
      </div>

      <div className="whoAreWe-container scroll-snap-section">
        <img className="whoAreWe-blob whoAreWe-blob-light homepage-blob" src="/blob-whoAreWe-light.svg" alt="blob-whoAreWe-light.svg" />
        <img className="whoAreWe-blob whoAreWe-blob-dark homepage-blob" src="/blob-whoAreWe-dark.svg" alt="blob-whoAreWe-dark.svg" />
        <h4 className="whoAreWe-title title">{t("homepage.whoweare.title")}</h4>
        <div className="whoAreWe-content-container">
          <div className="whoAreWe-logo-container">
            <img className="whoAreWe-vegtax-logo whoAreWe-logo" src="/vegtaxLogo.png" alt="vegtaxLogo.png" />
            <img className="whoAreWe-vegconsulting-logo whoAreWe-logo" src="/vegconsulting-logo.svg" alt="vegconsulting-logo.svg" />
          </div>
          <div className="whoAreWe-text-container">
            <p className="whoAreWe-text">{t("homepage.whoweare.textStart")}</p>
            <p className="whoAreWe-text">{t("homepage.whoweare.textEnd")}</p>
          </div>
          <Button className="whoAreWe-button" text={t("homepage.whoweare.aboutus")} onClick={() => navigate("/whoweare")}></Button>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
