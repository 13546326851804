import React from "react";
import "./footer.css";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

function Footer(props) {
  const navigate = useNavigate();
  const [t] = useTranslation("global");

  const location = useLocation();

  const handlePageRoute = (path) => {
    navigate(path);
  };
  return (
    <div className="footer">
      <div className="footer-upper-section">
        <div className="footer-logo-nav">
          <img
            src="/vegtaxFooterLogo.png"
            alt="company logo"
            className="footer-logo-img"
            onClick={() => handlePageRoute("/")}
          />
          <div className="footer-navigation">
            <ul className="footer-navigation-list">
              <li onClick={() => handlePageRoute("/privacypolicy")}>
                <span
                  className={
                    location.pathname === "/privacypolicy"
                      ? "nav-li-text link-active"
                      : "nav-li-text"
                  }
                  style={{ fontSize: "12px" }}
                >
                  {t("menu.priv")}
                </span>
              </li>
              <li onClick={() => handlePageRoute("/rulesandconditions")}>
                <span
                  className={
                    location.pathname === "/rulesandconditions"
                      ? "nav-li-text link-active"
                      : "nav-li-text"
                  }
                  style={{ fontSize: "12px" }}
                >
                  {t("generalconditions")}
                </span>
              </li>
            </ul>
          </div>
          <div className="footer-contact-infos-container">
            <div className="email-container footer-contact-container">
              <img className="footer-contact-icon" src="/mail-icon.svg" alt="mail-icon"></img>
              <a href="mailto:info@vegtax.ch" className="footer-contact-text">info@vegtax.ch</a>
            </div>
            <div className="phone-container footer-contact-container">
              <img className="footer-contact-icon" src="/phone-icon.svg" alt="phone-icon"></img>
              <a href="tel:0216348500" className="footer-contact-text">021 634 85 00</a>
            </div>
          </div>
        </div>

        <div className="footer-adress">
          <div className="adress-text" >
            <p>VEG Consulting Sàrl</p>
          </div>
          <div className="adress-text" >
            <p>Avenue du Théâtre 7</p>
          </div>
          <div className="adress-text" >
            <p>1005 Lausanne</p>
          </div>
        </div>

        <div className="poweredBy-container">
          <div className="poweredBy">
            <p>{t("powered")}</p>
          </div>
          <a href="https://www.vegconsulting.ch/" target="_blank" rel="noopener noreferrer">
            <img className="poweredBy-logo" src="/veg-icon-new.svg" width={30} alt="powered by" />
          </a>
        </div>

      </div>
      <div className="footer-bottom-section">
        <p>
          © 2024 <span style={{ fontFamily: "Nunito Medium" }}>VEG</span>
          <span className="hightlight-text-blue" style={{ fontFamily: "Nunito Medium", color: "#55C9EA" }}>
            TAX.
          </span>
          &nbsp;
          {t("allrights")}
        </p>
      </div>
    </div>
  );
}

export default Footer;
