import React from "react";
import Success from "../../components/success";
import OurConcept from "../../components/ourConcept";

function OurConceptPage(props) {
  return (
    <div className="page-container">
      <OurConcept />
    </div>
  );
}

export default OurConceptPage;
