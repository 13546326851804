import React from "react";
import "./layout.css";
import Header from "../Header";
import { Outlet } from "react-router";
import Footer from "../Footer";

function Layout(props) {
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default Layout;
