import React, { useRef, useEffect, useState } from "react";
import "./payment.css";
import Receipt from "../receipt";
import { useAppState } from "../../contexts/formContext";
import StripeContainer from "../stripeContainer";
import StripeWrapper from "../stripeContainer";
import "@adyen/adyen-web/dist/adyen.css";
import AdyenCheckout from "@adyen/adyen-web";
import axios from "axios";
import ValidationError from "../validationError";
import { useTranslation } from "react-i18next";
import url from "../../constants";
import { useNavigate } from "react-router";
import {Session} from 'connect-sdk-client-js'


function Payment({ isSuccess, checkoutErrors, serverError }) {
  const twintContainerRef = useRef();
  const [state, setState] = useAppState();
  const [activePaymentMethod, setActivePaymentMethod] = useState();

  const [success, setSuccess] = useState(false);
  const [rulesError, setRulesError] = useState(true);
  const [sendingForm, setSendingForm] = useState(false);
  const navigate = useNavigate();
  const [redirectUrl, setRedirectUrl] = useState();

  const [redirectUrlForIframe, setRedirectUrlForIframe] = useState(null);

  const [
    personalInformationValidationErrors,
    setPersonalInformationValidationErrors,
  ] = useState();
  const [emailError, setEmailError] = useState();
  const [phoneError, setPhoneError] = useState();
  const [streetNameAndNumberError, setStreetNameAndNumberError] = useState();
  const [streetPostalCodeError, setPostalCodeError] = useState();
  const [cityNameError, setCityNameError] = useState();
  const [firstNameError, setFirstNameError] = useState();
  const [lastNameError, setLastNameError] = useState();

  const [sendingRequest, setSendingRequest] = useState(false);
  const [t, i18n] = useTranslation("global");

  const selectActivePaymentMethod = (method) => {
    if (method === activePaymentMethod) {
      return;
    } else {
      setActivePaymentMethod(method);
    }
  };

  function isValidPhone(phone) {
    if (!phone) {
      return false;
    }
    return /^[+]*(?:\d[+]*){6,12}$/.test(phone);
  }

  function isValidEmail(email) {
    if (!email) {
      return false;
    }
    return /\S+@\S+\.\S+/.test(email);
  }

  function isValidStreetNameAndNumber(streetNameAndNumber) {
    if (!streetNameAndNumber) {
      return false;
    }
    return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\s]+$/.test(streetNameAndNumber);
  }

  function isValidPostalCode(postalCode) {
    if (!postalCode) {
      return false;
    }
    return /^(?=.*\d)[A-Za-z\d\s-]+$/.test(postalCode);
  }

  function isValidCityName(cityName) {
    if (!cityName) {
      return false;
    }
    return /^(?=.*)[A-Za-z\s]+$/.test(cityName);
  }

  function isValidName(name) {
    if (!name) {
      return false;
    }
    return/^[A-Za-z]+$/.test(name);
  }

  const handleSelectRules = () => {
    setState((state) => {
      if (state?.rules) {
        setRulesError(true);
        return { ...state, rules: false };
      } else {
        setRulesError(false);
        return {
          ...state,
          rules: true,
        };
      }
    });
  };

   const handleSubmit = (methodValue) => {
    setSendingForm(true);
    selectActivePaymentMethod(methodValue);
    setRedirectUrlForIframe(null);
    setState((state) => {
      return {
        ...state,
        serverError: null,
      };
    });
    const { serverError, ...restOfState } = state;
    const modifiedStateWithNewPrice = {
      ...restOfState,
      price: state?.price * 100, // Modify the price by multiplying it by 100
    };
    try{
     if (methodValue === 'Card' || methodValue === 'Twint'){
      axios
        .post(
          `${url}initialize-payment-iframe`,
          { data: 
            { 
              form: modifiedStateWithNewPrice,
              paymentType: methodValue,
              language: i18n.language,
            } 
          },
          { withCredentials: true }
        )
        .then((res) => {
          //setSendingForm(false);
          setRedirectUrlForIframe(res.data);
          setSendingForm(false);  
        }).catch((error) => {
          setSuccess(false);
          setSendingForm(false);
          setState((state) => {
            return {
              ...state,
              serverError: error,
            };
          });
          setActivePaymentMethod(null);
          console.log(error);
        });;
      }
      else if (methodValue === 'PayPal' || methodValue === 'PF Pay'){
        axios
          .post(
            `${url}initialize-payment-url`,
            { data: 
              { 
                form: modifiedStateWithNewPrice,
                paymentType: methodValue,
                language: i18n.language,
              } 
            },
            { withCredentials: true }
          )
          .then((res) => {
            //setSendingForm(false);
             setState((state) => {
               return { ...state, redirectUrl: res.data };
             });        
          }).catch((error) => {
            setSuccess(false);
            setSendingForm(false);
            setState((state) => {
              return {
                ...state,
                serverError: error,
              };
            });
            setActivePaymentMethod(null);
            console.log(error);
          });
        }
      }catch(error){
        console.log(error);
      }
   };

   const handlePaymentForClient = () => {
      const sessionDetails = {
        clientSessionId: "",
        customerId: "",
        clientApiUrl: "",
        assetURL: "",
      }

      const newSession = new Session(sessionDetails);

      const nasicPaymentItems = newSession.getBasicPaymentItems()
   }

  const handleSubmit2 = () => {
    setSendingForm(true);
    const {
      serverError,
      isSuccess,
      redirectUrl,
      checkoutErrors,
      rules,
      goToPayment,
      step,
      ...rest
    } = state;
    axios
      .post(
        `${url}save-form`,
        {
          form: rest,
          amount: state?.price * 100,
          userId: serverError?.response?.data?.id,
        },
        { withCredentials: true }
      )
      .then((res) => {
        setSendingForm(false);

        setState((state) => {
          return {
            ...state,
            redirectUrl:
              res?.data?.createHostedCheckoutResponse?.body?.redirectUrl,
            isSuccess: res?.data?.createHostedCheckoutResponse?.isSuccess,
            checkoutErrors:
              res?.data?.createHostedCheckoutResponse?.body?.errors?.[0]
                ?.message,
          };
        });
      })
      .catch((error) => {
        setState((state) => {
          return {
            ...state,
            serverError: error,
          };
        });

        setSendingForm(false);
      });
  };

  const testSession = () => {
    console.log(state);
    setSendingForm(true);
    axios
      .post(
        `${url}send-form`,
        {
          form: state,
        },
        { withCredentials: true }
      )
      .then((res) => {
        //setServerError(false);
        setSendingForm(false);
        setSuccess(true);
        console.log(res);
      })
      .catch((error) => {
        setSuccess(false);
        console.log(error);
        //setServerError(error);
        setSendingForm(false);
      });
  };

  const validatePersonalInformation = (form) => {
    const requiredFields = ["name and lastname", "email", "mobile"];
    if (!form) {
      setPersonalInformationValidationErrors(true);
    } else {
      // requiredFields.forEach((key) => {
      //   console.log(key);
      //   if (!form[key]) {
      //     setPersonalInformationValidationErrors((errors) => {
      //       return { ...errors, [key]: key };
      //     });

      //   }
      // });
      const result = requiredFields.every((key) => form[key]);
      if (result === true) {
        setPersonalInformationValidationErrors(false);
      } else {
        setPersonalInformationValidationErrors(true);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    //testSession();
    return () => {};
  }, []);

  useEffect(() => {
    validatePersonalInformation(state?.personalInformation);
    setEmailError(isValidEmail(state?.personalInformation?.email));
    setPhoneError(isValidPhone(state?.personalInformation?.mobile));
    setStreetNameAndNumberError(isValidStreetNameAndNumber(state?.personalInformation?.streetNameAndNumber));
    setPostalCodeError(isValidPostalCode(state?.personalInformation?.postalcode));
    setCityNameError(isValidCityName(state?.personalInformation?.cityName));
    setFirstNameError(isValidName(state?.personalInformation?.firstName));
    setLastNameError(isValidName(state?.personalInformation?.lastName));
  }, [state]);

  const paymentMethods = [
    {
      id: "1", 
      value: "Card",
      frenchValue: "Carte",
      imageSources: [
        "/visa-icon.svg", "/mastercard-icon.svg", "american-express-icon.svg", "bancontact-icon.svg",
        "/elv-sepa-icon.svg", "jcb-icon.svg", "/unionpay-icon.svg", "/diners-club-icon.svg"
      ],
      type: "Card"
    },
    {
      id: "2",
      value: "TWINT",
      frenchValue: "TWINT",
      imageSources: ["/twint-icon.svg"],
      type: "Twint"
    },
    {
      id: "3",
      value: "PayPal",
      frenchValue: "PayPal",
      imageSources: ["/paypal-icon.svg"],
      type: "PayPal"
    },
    {
      id: "4",
      value: "PostFinance Pay",
      frenchValue: "PostFinance Pay",
      imageSources: ["/postfinancepay-icon.svg"],
      type: "PF Pay"
    },
  ];

  useEffect(() => {
    const createSession = async () => {
      const res = await axios.post("https://api.vegtax.ch/api/session");
      const configuration = {
        environment: "test", // Change to 'live' for the live environment.
        clientKey: "test_X4YYKZGRUJHDDGWMJLATJFZGHAFNAGHQ", // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication

        session: {
          id: res.data.id,
          sessionData: res.data.sessionData,
        },
        onPaymentCompleted: (result, component) => {
          console.info(result, component);
        },
        onError: (error, component) => {
          console.error(error.name, error.message, error.stack, component);
        },
        // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
        // For example, this is 3D Secure configuration for cards:
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            billingAddressRequired: true,
          },
        },
      };
      const checkOut = await AdyenCheckout(configuration);

      if (twintContainerRef.current) {
        checkOut.create("twint").mount(twintContainerRef.current);
      }
    };

    
    // const createCheckout = async () => {
    //   const checkOut = await AdyenCheckout({});
    //   if (twintContainerRef.current) {
    //     checkOut.create("twint").mount(twintContainerRef.current);
    //   }
    // };
    // createCheckout();
    // if (activePaymentMethod === "Twint") {
    //   createSession();
    // }
  }, [activePaymentMethod]);

  const send = () => {
    axios
      .post(
        `${url}send-form`,
        {
          test: "test",
        },
        { withCredentials: true }
      )
      .then((res) => {
        // console.log(res);
      })
      .catch((error) => {
        console.log(error);
        console.log("inside catch");
      });
  };

  const handleChangePersonalEnformation = (key, e) => {
    setState((state) => {
      return {
        ...state,
        personalInformation: {
          ...state.personalInformation,
          [key]: e.target.value.trimStart(),
        },
      };
    });
  };

  useEffect(() => {
    const handleMessage = (event) => {
        if (event.data.status === 'success') {
          navigate("/success", { state: { pathname: "/tax-refund" } })
        } else if (event.data.status === 'failure') {
            navigate("/refusal", { state: { pathname: "/tax-refund" } })
        }
    };

    window.addEventListener('message', handleMessage);

    // Cleanup on component unmount
    return () => window.removeEventListener('message', handleMessage);
    }, [navigate]);

  return (
    <div style={{ position: "relative" }}>
      <h4 className="payment-component-title">{t("payment.title")}</h4>
      <Receipt step={state?.step} price={state?.price} />
      <div
        className="personal-information-form"
        style={{ position: "relative" }}
      >
        <h4 style={{ marginBottom: "30px" }} className="checkboxes-title">
          {i18n.language === "fr" ? "Coordonnées" : "Contact details"}
        </h4>
        {/*}
        <ValidationError
          error={personalInformationValidationErrors}
          message={t("reqfields")}
          topPosition={"30px"}
        />
        
        <div className="regular-input-wrapper">
          <input
            type="text"
            className="activity-input"
            onChange={(e) =>
              handleChangePersonalEnformation("name and lastname", e)
            }
            value={state?.personalInformation?.["name and lastname"] || ""}
          />
          <div className="select-label">{t("trefundstep4.fields.nlname")}</div>
        </div>
        */}
        <div className="regular-input-wrapper" style={{ position: "relative" }}>
          <ValidationError
            error={firstNameError === false}
            message={i18n.language === "fr" ? "Le prénom est invalide" : "First name is invalid"}
            topPosition={"-20px"}
          />
          <input
            type="text"
            className="activity-input"
            onChange={(e) => handleChangePersonalEnformation("firstName", e)}
            value={state?.personalInformation?.firstName || ""}
          />
          <div className="select-label">{i18n.language === "fr" ? "Prénom:" : "First name:"}:</div>
        </div>
        <div className="regular-input-wrapper" style={{ position: "relative" }}>
          <ValidationError
            error={lastNameError === false}
            message={i18n.language === "fr" ? "Le nom de famille est invalide" : "Last name is invalid"} 
            topPosition={"-20px"}
          />
          <input
            type="text"
            className="activity-input"
            onChange={(e) => handleChangePersonalEnformation("lastName", e)}
            value={state?.personalInformation?.lastName || ""}
          />
          <div className="select-label">{i18n.language === "fr" ? "Nom:" : "Last name:"}</div>
        </div>
        <div className="regular-input-wrapper" style={{ position: "relative" }}>
          <ValidationError
            error={emailError === false}
            message={i18n.language === "fr" ? "L'email est invalide" : "Email is invalid"}
            topPosition={"-20px"}
          />
          <input
            type="text"
            className="activity-input"
            onChange={(e) => handleChangePersonalEnformation("email", e)}
            value={state?.personalInformation?.email || ""}
          />
          <div className="select-label">Email:</div>
        </div>
        <div className="regular-input-wrapper" style={{ position: "relative" }}>
          <ValidationError
            error={phoneError === false}
            message={i18n.language === "fr" ? "Le numéro de mobile est invalide" : "Phone number is invalid"}
            topPosition={"-20px"}
          />
          <input
            type="text"
            className="activity-input"
            onChange={(e) => handleChangePersonalEnformation("mobile", e)}
            value={state?.personalInformation?.mobile || ""}
          />
          <div className="select-label">Mobile:</div>
        </div>
        <div className="regular-input-wrapper" style={{ position: "relative" }} >
          <ValidationError
            error={streetNameAndNumberError === false}
            message={i18n.language === "fr" ? "La rue et le numéro sont invalides" : "Street and number are invalid"}
            topPosition={"-20px"}
          />
          <input
            type="text"
            className="activity-input"
            onChange={(e) => handleChangePersonalEnformation("streetNameAndNumber", e)}
            value={state?.personalInformation?.streetNameAndNumber || ""}
          />
          <div className="select-label">{i18n.language === "fr" ? "Rue + numéro:" : "Street + number:"}</div>
        </div>
        <div className="regular-input-wrapper" style={{ position: "relative" }} >
          <ValidationError
            error={streetPostalCodeError === false}
            message={i18n.language === "fr" ? "Le code postal est invalide" : "Postcode is invalid"}
            topPosition={"-20px"}
          />
          <input
            type="text"
            className="activity-input"
            onChange={(e) => handleChangePersonalEnformation("postalcode", e)}
            value={state?.personalInformation?.postalcode || ""}
          />
          <div className="select-label">{i18n.language === "fr" ? "Code postal:" : "Postcode:"}</div>
        </div>
        <div className="regular-input-wrapper" style={{ position: "relative" }} >
          <ValidationError
            error={cityNameError === false}
            message={i18n.language === "fr" ? "La ville est invalide" : "City is invalid"}
            topPosition={"-20px"}
          />
          <input
            type="text"
            className="activity-input"
            onChange={(e) => handleChangePersonalEnformation("cityName", e)}
            value={state?.personalInformation?.cityName || ""}
          />
          <div className="select-label">{i18n.language === "fr" ? "Ville:" : "City:"}</div>
        </div>
        {/*
        <div className="regular-input-wrapper">
          <input
            type="text"
            className="activity-input"
            onChange={(e) => handleChangePersonalEnformation("address", e)}
            value={state?.personalInformation?.address || ""}
          />
          <div className="select-label">{t("trefundstep2.fields.addr")}</div>
        </div>
        */}
      </div>
      <div
        className="no-checkbox-block"
        style={{ margin: "30px 0", paddingLeft: "20px", position: "relative" }}
      >
        <ValidationError error={!state?.rules} topPosition={"-13px"} />
        <div className="no-checkbox" onClick={() => handleSelectRules()}>
          {state?.rules === true && (
            <div className="no-checkbox-mark">
              <img
                src="/checkMarkIcon.png"
                alt="checkmark"
                className="check-mark-icon"
              />
            </div>
          )}
        </div>
        <p className="no">
          {t("ihaveread")}{" "}
          <span
            onClick={() => navigate("/rulesandconditions")}
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            {t("gencon")}
          </span>
        </p>
      </div>
      { 
        firstNameError === true &&
        lastNameError === true && 
        emailError === true &&
        phoneError === true &&
        streetNameAndNumberError === true &&
        streetPostalCodeError === true &&
        cityNameError === true &&
        state.rules === true ? 
        <ul style={{marginTop:"40px"}}>
          <li key="CARD" style={{marginTop: "10px"}} >
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                  <input 
                    type="radio" 
                    id={`payment-button-${paymentMethods[0].id}`}
                    title={`payment-button-${paymentMethods[0].id}`}
                    onClick={() => handleSubmit(paymentMethods[0].type)}
                    style={{ width: "35px", height: "35px", transform: "scale(0.9)", padding:"15px"}}
                    name="payment"
                    checked={activePaymentMethod === paymentMethods[0].type}
                  />
                  <div>
                    <div  style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                      <img src="/credit-card.svg" height={50} ></img>
                      <label style={{fontFamily: "Nunito Medium"}} >{i18n.language === "en" ? paymentMethods[0].value : paymentMethods[0].frenchValue}</label>
                    </div>
                    <div className="card-container" >
                      <div id={paymentMethods[0].imageSources[0]} style={{ width: "50px", height: "30px", overflow: "hidden", marginTop: "5px" }} >
                        <img src={paymentMethods[0].imageSources[0]} width={90} style={{ padding: "2px", width: "100%", height: "100%", objectFit: "cover", border: "1px solid gray", borderRadius: "3px" }}/>
                      </div>
                      <div id={paymentMethods[0].imageSources[1]} style={{ width: "50px", height: "30px", overflow: "hidden", marginTop: "5px"}} >
                        <img src={paymentMethods[0].imageSources[1]} width={90} style={{ padding: "2px", width: "100%", height: "100%", objectFit: "contain", border: "1px solid gray", borderRadius: "3px" }}/>
                      </div>
                      <div id={paymentMethods[0].imageSources[2]} style={{ width: "50px", height: "30px", overflow: "hidden", marginTop: "5px" }} >
                        <img src={paymentMethods[0].imageSources[2]} width={90} style={{ width: "100%", height: "100%", objectFit: "cover", border: "1px solid gray", borderRadius: "3px" }}/>
                      </div>
                      <div id={paymentMethods[0].imageSources[3]} style={{ width: "50px", height: "30px", overflow: "hidden", marginTop: "5px" }} >
                        <img src={paymentMethods[0].imageSources[3]} width={90} style={{  width: "100%", height: "100%", objectFit: "contain", border: "1px solid gray", borderRadius: "3px" }}/>
                      </div>
                      <div id={paymentMethods[0].imageSources[4]} style={{ width: "50px", height: "30px", overflow: "hidden", marginTop: "5px" }} >
                        <img src={paymentMethods[0].imageSources[4]} width={90} style={{ width: "100%", height: "100%", objectFit: "contain", border: "1px solid gray", borderRadius: "3px" }}/>
                      </div>
                      <div id={paymentMethods[0].imageSources[5]} style={{ width: "50px", height: "30px", overflow: "hidden", marginTop: "5px" }} >
                        <img src={paymentMethods[0].imageSources[5]} width={90} style={{  width: "100%", height: "100%", objectFit: "contain", border: "1px solid gray", borderRadius: "3px" }}/>
                      </div>
                      <div id={paymentMethods[0].imageSources[6]} style={{ width: "50px", height: "30px", overflow: "hidden", marginTop: "5px" }} >
                        <img src={paymentMethods[0].imageSources[6]} width={90} style={{  width: "100%", height: "100%", objectFit: "cover", border: "1px solid gray", borderRadius: "3px" }}/>
                      </div>
                      <div id={paymentMethods[0].imageSources[7]} style={{ width: "50px", height: "30px", overflow: "hidden", marginTop: "5px" }} >
                        <img src={paymentMethods[0].imageSources[7]} width={90} style={{  width: "100%", height: "100%", objectFit: "cover", border: "1px solid gray", borderRadius: "3px" }}/>
                      </div>
                    </div>
                  </div>
            </div>
          </li>
          <div style={{borderTop:"1px solid lightgray", margin:"20px 0px", borderRadius:"2px"} }></div>
          <li key="TWINT" style={{marginTop: "10px"}} >
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                  <input 
                    type="radio" 
                    id={`payment-button-${paymentMethods[1].id}`}
                    title={`payment-button-${paymentMethods[1].id}`}
                    onClick={() => handleSubmit(paymentMethods[1].type)}
                    style={{ width: "35px", height: "35px", transform: "scale(0.9)", padding:"15px"}}
                    name="payment"
                    checked={activePaymentMethod === paymentMethods[1].type}
                  />
                  {
                    paymentMethods[1].imageSources.map((imageSource) => (
                      <div id={imageSource} style={{ width: "100px", height: "45px", overflow: "hidden" }} >
                        <img src={imageSource} width={90} style={{  width: "100%", height: "100%", objectFit: "cover", border: "1px solid gray", borderRadius: "3px" }}/>
                      </div>
                    ))
                  }
                  <label style={{fontFamily: "Nunito Medium"}} >{i18n.language === "en" ? paymentMethods[1].value : paymentMethods[1].frenchValue}</label>
              </div>
          </li>
          <div style={{borderTop:"1px solid lightgray", margin:"20px 0px", borderRadius:"2px"} }></div>
          <li key="PAYPAL" style={{marginTop: "10px"}}>
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                  <input 
                    type="radio" 
                    id={`payment-button-${paymentMethods[2].id}`}
                    title={`payment-button-${paymentMethods[2].id}`}
                    onClick={() => handleSubmit(paymentMethods[2].type)}
                    style={{ width: "35px", height: "35px", transform: "scale(0.9)", padding:"15px"}}
                    name="payment"
                    checked={activePaymentMethod === paymentMethods[2].type}
                  />
                  {
                    paymentMethods[2].imageSources.map((imageSource) => (
                      <div id={imageSource} style={{ width: "100px", height: "45px", overflow: "hidden" }} >
                        <img src={imageSource} width={90} style={{ padding: "2px", width: "100%", height: "100%", objectFit: "contain", border: "1px solid gray", borderRadius: "3px" }}/>
                      </div>
                    ))
                  }
                  <label style={{fontFamily: "Nunito Medium"}} >{i18n.language === "en" ? paymentMethods[2].value : paymentMethods[2].frenchValue}</label>
              </div>  
          </li>
          <div style={{borderTop:"1px solid lightgray", margin:"20px 0px", borderRadius:"2px"} }></div>
          <li key="FPPAY" style={{marginTop: "10px"}} >
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                  <input 
                    type="radio" 
                    id={`payment-button-${paymentMethods[3].id}`}
                    title={`payment-button-${paymentMethods[3].id}`}
                    onClick={() => handleSubmit(paymentMethods[3].type)}
                    style={{ width: "35px", height: "35px", transform: "scale(0.9)", padding:"15px"}}
                    name="payment"
                    checked={activePaymentMethod === paymentMethods[3].type}
                  />
                  {
                    paymentMethods[3].imageSources.map((imageSource) => (
                      <div id={imageSource} style={{ width: "100px", height: "45px", overflow: "hidden" }} >
                        <img src={imageSource} width={90} style={{width: "100%", height: "100%", objectFit: "cover", border: "1px solid gray", borderRadius: "3px" }}/>
                      </div>
                    ))
                  }
                  <label style={{fontFamily: "Nunito Medium"}} >{i18n.language === "en" ? paymentMethods[3].value : paymentMethods[3].frenchValue}</label>
              </div>  
          </li>
        </ul>
        :
        <></>
      }
      {/*
        <ul style={{marginTop:"40px"}}>
        <li key="CARD" style={{marginTop: "10px"}} >
          <div style={{ display: "flex", alignItems: "flex-start", gap: "20px" }} >
                <input 
                  type="radio" 
                  id={`payment-button-${paymentMethods[0].id}`}
                  title={`payment-button-${paymentMethods[0].id}`}
                  onClick={() => handleSubmit(paymentMethods[0].type)}
                  style={{ width: "35px", height: "35px", transform: "scale(0.9)", padding:"15px"}}
                  name="payment"
                />
                <div>
                  <div  style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <img src="/credit-card.svg" height={50} ></img> 
                    <label style={{fontFamily: "Nunito Medium"}} >{i18n.language === "en" ? paymentMethods[0].value : paymentMethods[0].frenchValue}</label>
                  </div>
                  <div style = {{ display: "flex", gap: "10px"}} >
                    <button id={paymentMethods[0].imageSources[0]} style={{ width: "50px", height: "30px", overflow: "hidden", marginTop: "5px" }} >
                      <img src={paymentMethods[0].imageSources[0]} width={90} style={{ padding: "2px", width: "100%", height: "100%", objectFit: "cover", border: "1px solid gray", borderRadius: "3px" }}/>
                    </button>
                    <button id={paymentMethods[0].imageSources[1]} style={{ width: "50px", height: "30px", overflow: "hidden", marginTop: "5px"}} >
                      <img src={paymentMethods[0].imageSources[1]} width={90} style={{ padding: "2px", width: "100%", height: "100%", objectFit: "contain", border: "1px solid gray", borderRadius: "3px" }}/>
                    </button>
                    <button id={paymentMethods[0].imageSources[2]} style={{ width: "50px", height: "30px", overflow: "hidden", marginTop: "5px" }} >
                      <img src={paymentMethods[0].imageSources[2]} width={90} style={{ width: "100%", height: "100%", objectFit: "cover", border: "1px solid gray", borderRadius: "3px" }}/>
                    </button>
                    <button id={paymentMethods[0].imageSources[3]} style={{ width: "50px", height: "30px", overflow: "hidden", marginTop: "5px" }} >
                      <img src={paymentMethods[0].imageSources[3]} width={90} style={{  width: "100%", height: "100%", objectFit: "contain", border: "1px solid gray", borderRadius: "3px" }}/>
                    </button>
                    <button id={paymentMethods[0].imageSources[4]} style={{ width: "50px", height: "30px", overflow: "hidden", marginTop: "5px" }} >
                      <img src={paymentMethods[0].imageSources[4]} width={90} style={{ width: "100%", height: "100%", objectFit: "contain", border: "1px solid gray", borderRadius: "3px" }}/>
                    </button>
                    <button id={paymentMethods[0].imageSources[5]} style={{ width: "50px", height: "30px", overflow: "hidden", marginTop: "5px" }} >
                      <img src={paymentMethods[0].imageSources[5]} width={90} style={{  width: "100%", height: "100%", objectFit: "contain", border: "1px solid gray", borderRadius: "3px" }}/>
                    </button>
                    <button id={paymentMethods[0].imageSources[6]} style={{ width: "50px", height: "30px", overflow: "hidden", marginTop: "5px" }} >
                      <img src={paymentMethods[0].imageSources[6]} width={90} style={{  width: "100%", height: "100%", objectFit: "cover", border: "1px solid gray", borderRadius: "3px" }}/>
                    </button>
                    <button id={paymentMethods[0].imageSources[7]} style={{ width: "50px", height: "30px", overflow: "hidden", marginTop: "5px" }} >
                      <img src={paymentMethods[0].imageSources[7]} width={90} style={{  width: "100%", height: "100%", objectFit: "cover", border: "1px solid gray", borderRadius: "3px" }}/>
                    </button>
                  </div>
                </div>
          </div>
        </li>
        <div style={{borderTop:"1px solid lightgray", margin:"20px 0px", borderRadius:"2px"} }></div>
        <li key="TWINT" style={{marginTop: "10px"}} >
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                <input 
                  type="radio" 
                  id={`payment-button-${paymentMethods[1].id}`}
                  title={`payment-button-${paymentMethods[1].id}`}
                  onClick={() => handleSubmit(paymentMethods[1].type)}
                  style={{ width: "35px", height: "35px", transform: "scale(0.9)", padding:"15px"}}
                  name="payment"
                />
                {
                  paymentMethods[1].imageSources.map((imageSource) => (
                    <div id={imageSource} style={{ width: "100px", height: "45px", overflow: "hidden" }} >
                      <img src={imageSource} width={90} style={{  width: "100%", height: "100%", objectFit: "cover", border: "1px solid gray", borderRadius: "3px" }}/>
                    </div>
                  ))
                }
                <label style={{fontFamily: "Nunito Medium"}} >{i18n.language === "en" ? paymentMethods[1].value : paymentMethods[1].frenchValue}</label>
            </div>
        </li>
        <div style={{borderTop:"1px solid lightgray", margin:"20px 0px", borderRadius:"2px"} }></div>
        <li key="PAYPAL" style={{marginTop: "10px"}}>
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                <input 
                  type="radio" 
                  id={`payment-button-${paymentMethods[2].id}`}
                  title={`payment-button-${paymentMethods[2].id}`}
                  onClick={() => handleSubmit(paymentMethods[2].type)}
                  style={{ width: "35px", height: "35px", transform: "scale(0.9)", padding:"15px"}}
                  name="payment"
                />
                {
                  paymentMethods[2].imageSources.map((imageSource) => (
                    <div id={imageSource} style={{ width: "100px", height: "45px", overflow: "hidden" }} >
                      <img src={imageSource} width={90} style={{ padding: "2px", width: "100%", height: "100%", objectFit: "contain", border: "1px solid gray", borderRadius: "3px" }}/>
                    </div>
                  ))
                }
                <label style={{fontFamily: "Nunito Medium"}} >{i18n.language === "en" ? paymentMethods[2].value : paymentMethods[2].frenchValue}</label>
            </div>  
        </li>
        <div style={{borderTop:"1px solid lightgray", margin:"20px 0px", borderRadius:"2px"} }></div>
        <li key="FPPAY" style={{marginTop: "10px"}} >
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                <input 
                  type="radio" 
                  id={`payment-button-${paymentMethods[3].id}`}
                  title={`payment-button-${paymentMethods[3].id}`}
                  onClick={() => handleSubmit(paymentMethods[3].type)}
                  style={{ width: "35px", height: "35px", transform: "scale(0.9)", padding:"15px"}}
                  name="payment"
                  
                />
                {
                  paymentMethods[3].imageSources.map((imageSource) => (
                    <div id={imageSource} style={{ width: "100px", height: "45px", overflow: "hidden" }} >
                      <img src={imageSource} width={90} style={{width: "100%", height: "100%", objectFit: "cover", border: "1px solid gray", borderRadius: "3px" }}/>
                    </div>
                  ))
                }
                <label style={{fontFamily: "Nunito Medium"}} >{i18n.language === "en" ? paymentMethods[3].value : paymentMethods[3].frenchValue}</label>
            </div>  
        </li>
      </ul>
      */}
      {/*<ul className="payment-buttons-wrapper"> 
        {
          paymentMethods.map((method) => {
            return (
              <li key={method.value}>
                <button
                  id={`payment-button-${method.id}`}
                  className="payment-button"
                  onClick={() => handleSubmit(method.value)}
                  style={{
                    backgroundColor:
                      method.value === activePaymentMethod
                        ? "#E8DEF8"
                        : "transparent",
                  }}
                >
                  <span className="payment-button-text">
                    {i18n.language === "en" ? method.value : method.frenchValue}
                    {method.value === activePaymentMethod && (
                      <span className="payment-button-check">
                        <img src="checkBird.png" />
                      </span>
                    )}
                  </span>
                </button>
              </li>
            )
          })
        }
      </ul>
      */}
      <div style={{ marginTop: "30px", position: "relative" }}>
        <ValidationError
          error={serverError || checkoutErrors}
          message={serverError?.message || checkoutErrors}
          topPosition={"-20px"}
        />
        {redirectUrlForIframe !== null ? (
        <iframe
          src={redirectUrlForIframe}
          className="iframe"
          title="Embedded Content"
          loading="lazy"
        />) 
        : 
        <></>}
      </div>
      {/*{sendingForm && <div className="page-overlay"></div>}*/}
      {sendingForm && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
    </div>
  );
}

export default Payment;
