import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "./dependentchildren.css";
import { useAppState } from "../../contexts/formContext";
import DependentChild from "../DependentChild";
import ValidationError from "../validationError";
import { useTranslation } from "react-i18next";

function DependentChildren({ validationErrors, invalidateStep1Errors }) {
  const [state, setState] = useAppState();
  const [emptyChild, setEmptyChild] = useState(false);

  function generateYearsBetween(startYear = 2000, endYear) {
    const endDate = endYear || new Date().getFullYear();
    let years = [];

    for (var i = startYear; i <= endDate; i++) {
      years.push({ id: startYear, value: startYear, frenchValue: startYear });
      startYear++;
    }
    return years.reverse();
  }

  const [t, i18n] = useTranslation("global");

  const childStatusOptions = [
    { id: 1, value: "Student", frenchValue: "Etudiant" },
    { id: 2, value: "Employed", frenchValue: "Salarié" },
    { id: 3, value: "Apprentice", frenchValue: "Apprenti" },
    { id: 4, value: "Pupil", frenchValue: "Ecolier" },
    { id: 4, value: "None", frenchValue: "Sans" },
  ];

  const onChange = () => {
    setState((state) => {
      if (!state?.["Enfants a scharge"]) {
        invalidateStep1Errors("Enfants a scharge");
        invalidateStep1Errors("independendant seul avec enfant");
        invalidateStep1Errors("Enfants a scharge data");
        return {
          ...state,
          "Enfants a scharge": "no",
          "independendant seul avec enfant": null,
        };
      } else if (state?.["Enfants a scharge"] === "no") {
        const { "Enfants a scharge": children, ...rest } = state;
        return { ...rest };
      } else {
        invalidateStep1Errors("Enfants a scharge");
        invalidateStep1Errors("independendant seul avec enfant");
        invalidateStep1Errors("Enfants a scharge data");
        return {
          ...state,
          "Enfants a scharge": "no",
          "independendant seul avec enfant": null,
        };
      }
    });
  };

  const handleAddDependentChildren = () => {
    invalidateStep1Errors("Enfants a scharge");
    setState((state) => {
      if (!state?.["Enfants a scharge"]) {
        return {
          ...state,
          ["Enfants a scharge"]: [
            { id: uuidv4(), ["Annee de naissance"]: "", statut: "" },
          ],
        };
      } else {
        return {
          ...state,
          ["Enfants a scharge"]: [
            ...state["Enfants a scharge"],
            { id: uuidv4(), ["Annee de naissance"]: "", statut: "" },
          ],
        };
      }
    });
  };

  const validateCurrentChild = (list) => {
    if (list && list.length > 0) {
      const child = list[list.length - 1];
      if (child.birthYear > 0 && child.childStatus.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleChangeSingleHousehold = (val) => {
    invalidateStep1Errors("independendant seul avec enfant");
    setState((state) => {
      return {
        ...state,
        "independendant seul avec enfant": val,
      };
    });
  };

  return (
    <div className="dependentchildren-block">
      <div style={{ display: "flex", position: "relative" }}>
        <h6 className="input-title">{t("taxrefundstep1.fields.depcildren")}</h6>
        <div className="info-icon">
          <img src="/infoIcon.png" alt="compensations info" />
          <p className="show-info">{t("minor")}</p>
        </div>
        <ValidationError error={validationErrors["Enfants a scharge"]} />
      </div>

      <div className="add-btn-block">
        <button
          className="add-btn"
          disabled={state?.["Enfants a scharge"] === "no"}
          onClick={handleAddDependentChildren}
          type="button"
        >
          +{t("Add")}
        </button>
        <div className="no-checkbox-block marginZero">
          <div className="no-checkbox" onClick={onChange}>
            {state?.["Enfants a scharge"] === "no" && (
              <div className="no-checkbox-mark">
                <img
                  src="/checkMarkIcon.png"
                  alt="checkmark"
                  className="check-mark-icon"
                />
              </div>
            )}
          </div>
          <p className="no">{t("no")}</p>
        </div>
      </div>
      {Array.isArray(state?.["Enfants a scharge"]) &&
        state?.["Enfants a scharge"]?.length > 0 && (
          <div style={{ position: "relative" }}>
            <ul style={{ marginTop: "40px" }}>
              {state?.["Enfants a scharge"]?.map((child) => {
                return (
                  <DependentChild
                    key={child.id}
                    yearOptions={generateYearsBetween(1900)}
                    childStatusOptions={childStatusOptions}
                    child={child}
                    emptyChild={emptyChild}
                    invalidateStep1Errors={invalidateStep1Errors}
                  />
                );
              })}
            </ul>
            <ValidationError
              error={validationErrors["Enfants a scharge data"]}
              topPosition="-20px"
              message="Reqiured fields"
            />
          </div>
        )}
      {Array.isArray(state?.["Enfants a scharge"]) &&
        state?.["Enfants a scharge"]?.length > 0 && (
          <div className="single-parent-hausehold-block">
            <h6 className="input-title">
              {t("taxrefundstep1.fields.singlehousehold")}
            </h6>
            <div
              className="single-parent-hausehold-boxes"
              style={{ position: "relative" }}
            >
              <ValidationError
                error={validationErrors["independendant seul avec enfant"]}
                topPosition="-13px"
              />
              <div
                className="no-checkbox-block"
                style={{ marginBottom: "10px" }}
              >
                <div
                  className="no-checkbox"
                  onClick={() => handleChangeSingleHousehold("oui")}
                >
                  {state?.["independendant seul avec enfant"] === "oui" && (
                    <div className="no-checkbox-mark">
                      <img
                        src="/checkMarkIcon.png"
                        alt="checkmark"
                        className="check-mark-icon"
                      />
                    </div>
                  )}
                </div>
                <p className="no"> {t("yes")}</p>
              </div>
              <div className="no-checkbox-block">
                <div
                  className="no-checkbox"
                  onClick={() => handleChangeSingleHousehold("no")}
                >
                  {state?.["independendant seul avec enfant"] === "no" && (
                    <div className="no-checkbox-mark">
                      <img
                        src="/checkMarkIcon.png"
                        alt="checkmark"
                        className="check-mark-icon"
                      />
                    </div>
                  )}
                </div>
                <p className="no">{t("no")}</p>
              </div>
            </div>
          </div>
        )}
    </div>
  );
}

export default DependentChildren;
