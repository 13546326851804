import { createContext, useContext, useState } from "react";

export const FormStateContext = createContext({});

export function FormProvider({ children }) {
  const value = useState({ step: 1 });
  return (
    <FormStateContext.Provider value={value}>
      {children}
    </FormStateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(FormStateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppProvider");
  }
  return context;
}
