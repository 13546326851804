import React from "react";
import SendFormError from "../../components/sendFormError";

function SendFormErrorPage(props) {
  return (
    <div className="page-container">
      <SendFormError />
    </div>
  );
}

export default SendFormErrorPage;
