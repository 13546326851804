import React, { useRef, useState, useEffect } from "react";
import "./header.css";
import NavBar from "../NavBar";
import { useNavigate } from "react-router";
import LanguageSwitcher from "../languageSwitcher";

function Header(props) {
  const [navigationOpen, setNavigationOpen] = useState(false);
  const navigate = useNavigate();

  //Scoll effect on header
  let prevScrollpos = window.scrollY;
  const headerDiv = useRef(null)
  const [moveHeader, setMoveHeader] = useState(false);
  let headerBottom
  useEffect(() => {
    headerBottom = headerDiv.current.offsetTop + headerDiv.current.offsetHeight;
  }, [headerDiv]);

  window.addEventListener("scroll", () => {
    let currentScrollPos = window.pageYOffset;

    if (prevScrollpos > currentScrollPos || currentScrollPos < headerBottom + 20 || currentScrollPos == 0) {
      setMoveHeader(false)
    }
    else {
      setMoveHeader(true)
    }

    prevScrollpos = currentScrollPos;
  })

return (
  <div ref={headerDiv} className={"header " + (moveHeader ? "moveHeader" : undefined)}>
    <div className="logo-navbar-wrapper">
      <div className="logo" onClick={() => navigate("/")}>
        <img src="/vegtaxLogo.png" alt="vegtax-logo" />
      </div>
      <NavBar
        navigationOpen={navigationOpen}
        setNavigationOpen={setNavigationOpen}
      />
    </div>

    <div className="header-functions-block">
      <div className="language-switcher">
        <LanguageSwitcher />
      </div>
      <div
        className="hamburger"
        onClick={() => setNavigationOpen((prevState) => !prevState)}
      >
        <img src="/hamburger.png" alt="hamburger icon" />
      </div>
    </div>
  </div>
);
}

export default Header;