import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import ValidationError from "../validationError";
import axios from "axios";
import url from "../../constants";

function SendFormError(props) {
  const [state, setState] = useState({});
  const [serverError, setServerError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [emailError, setEmailError] = useState();

  const [sendingRequest, setSendingRequest] = useState(false);
  const [personalInformationErrors, setPersonalInformationValidationErrors] =
    useState();
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");

  const location = useLocation();

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   if (location?.state?.pathname === "/tax-refund") {
  //     navigate(location.pathname, { state: null });
  //   } else {
  //     navigate("/");
  //   }
  // }, []);

  const invalidatePersonalInformation = () => {
    setPersonalInformationValidationErrors(null);
  };

  useEffect(() => {
    let persRes = false;
    let emRes = false;
    const persInformationFields = ["name and lastname", "emai", "message"];

    persInformationFields.forEach((info) => {
      if (state?.[info]) {
        persRes = true;
      } else {
        persRes = false;
      }
    });

    if (persRes === true) {
      invalidatePersonalInformation();
    }

    if (isValidEmail(state.email)) {
      emRes = true;
    }

    if (emRes === true) {
      invalidateEmail();
    }
  }, [state]);

  const handleChangePersonalEnformation = (key, e) => {
    setState((state) => {
      return { ...state, [key]: e.target.value.trimStart() };
    });
  };

  const validatePersonalInformation = (form) => {
    let result = true;
    const requiredFields = ["name and lastname", "email", "message"];
    if (!form) {
      result = false;
      setPersonalInformationValidationErrors({
        "name and lastname": "name and lastname",
        email: "email",
        message: "message",
      });
    } else {
      requiredFields.forEach((key) => {
        if (!form[key]) {
          setPersonalInformationValidationErrors((errors) => {
            return { ...errors, [key]: key };
          });
          result = false;
        }
      });
    }

    return result;
  };

  const handleSubmit = (form) => {
    if (!validatePersonalInformation(form) || !validateEmail(form?.email)) {
      return;
    } else {
      sendContactForm();
    }
  };

  const sendContactForm = () => {
    try{
      setSendingRequest(true);
      axios
        .post(
          `${url}contact-form`,
          {
            nom: state?.["name and lastname"],
            email: state?.email,
            message: state.message,
          },
          { withCredentials: true }
        )
        .then((res) => {
          setServerError(false);
          setSendingRequest(false);
          setSuccess(true);
        })
        .catch((error) => {
          setSuccess(false);
          setServerError(error);
          setSendingRequest(false);
        });
      }catch(error){
        setSuccess(false);
        setServerError(error);
        setSendingRequest(false);
    }
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const validateEmail = (email) => {
    let result = true;
    if (email && !isValidEmail(email)) {
      setEmailError("Email is invalid");
      result = false;
    }

    return result;
  };

  const invalidateEmail = () => {
    setEmailError(null);
  };

  return (
    <div className="refund-desc">
      <div className="form-container fullwidth">
        <h4>Error happened during your form submission.</h4>

        <p className="text-regular">
          <span className="hightlight-text">Please contact us.</span>
        </p>

        {!success ? (
          <>
            <div
              className="personal-information-form"
              style={{ position: "relative" }}
            >
              <ValidationError
                error={personalInformationErrors}
                topPosition={"-25px"}
                message={t("reqfields")}
              />
              <ValidationError
                error={serverError}
                message={serverError?.message}
                topPosition={"-45px"}
              />
              <div className="regular-input-wrapper">
                <input
                  type="text"
                  className="activity-input"
                  onChange={(e) =>
                    handleChangePersonalEnformation("name and lastname", e)
                  }
                  value={state?.["name and lastname"] || ""}
                />
                <div className="select-label">
                  {t("trefundstep4.fields.nlname")}
                </div>
              </div>
              <div
                className="regular-input-wrapper"
                style={{ position: "relative" }}
              >
                <ValidationError
                  error={emailError}
                  message={emailError}
                  topPosition={"-20px"}
                />
                <input
                  type="text"
                  className="activity-input"
                  onChange={(e) => handleChangePersonalEnformation("email", e)}
                  value={state?.email || ""}
                />
                <div className="select-label">Email</div>
              </div>
              <div className="regular-input-wrapper ">
                <textarea
                  rows="4"
                  cols="50"
                  className="activity-input form-message"
                  onChange={(e) =>
                    handleChangePersonalEnformation("message", e)
                  }
                  value={state?.message || ""}
                />

                <div className="select-label" style={{ height: "10px" }}>
                  Message
                </div>
              </div>
            </div>
            <button
              className="next-step-button"
              onClick={() => handleSubmit(state)}
            >
              {t("send")}
            </button>
          </>
        ) : (
          <div className="message-sent">
            <h4>{t("messagesent")}</h4>
          </div>
        )}
      </div>

      <div
        className="homepage-hero"
        style={{ padding: "0", marginTop: "100px" }}
      >
        <div className="homepage-hero-image">
          <img src="/whyChoseVegTax.png" alt="why chose vegtax" />
        </div>
        <div className="homepage-hero-slogan-block">
          <h1 className="homepage-hero-slogan">
            <span
              className="homepage-hero-slogan-1"
              style={{ color: "#000E2B" }}
            >
              {t("how.why")} VEG<span style={{ color: "#55C9EA" }}>TAX</span>?
            </span>
          </h1>
          <ul
            style={{
              listStyle: "initial",
              paddingLeft: "20px",
              marginTop: "12px",
            }}
          >
            <li className="list">
              <p className="hightlight-text">{t("how.speed")}</p>
              <p className="text-regular">{t("how.our")}</p>
            </li>
            <li className="list">
              <p className="hightlight-text">{t("how.data")}</p>
              <p className="text-regular">{t("how.trust")}</p>
            </li>
            <li className="list">
              <p className="hightlight-text">{t("how.expert")}</p>
              <p className="text-regular">{t("how.ourteam")}</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="ready-to-taxrefund">
        <h3 className="big-title" style={{ marginBottom: "10px" }}>
          {t("how.ready")}
        </h3>
        <p
          className="ready-to-taxrefund-description"
          style={{ marginBottom: "10px" }}
        >
          {t("how.click")}&nbsp;
          <span style={{ fontWeight: "bold", fontFamily: "Nunito Medium" }}>
            VEG
          </span>
          <span style={{ color: "#55C9EA" }}>TAX</span>
          {t("how.guide")}
        </p>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <button
            className="get-tax-refund-btn"
            style={{ width: "125px" }}
            onClick={() => navigate("/tax-refund")}
          >
            {t("how.letsgo")}
          </button>
        </div>
      </div>
      {sendingRequest && <div className="page-overlay"></div>}
    </div>
  );
}

export default SendFormError;
