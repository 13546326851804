import "./button.css";

function Button(props) {

    const text = props.text
    const className = props.className
    const onClick = props.onClick

    return (
        <button className={className + " button-component"} onClick={onClick}>
            <div>
                {text}
            </div>
        </button>
    );
}

export default Button;