import React from "react";
import "./navbar.css";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const NavBar = ({ navigationOpen, setNavigationOpen }) => {
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();

  const handlePageRoute = (path) => {
    navigate(path);
    setNavigationOpen(false);
  };



  return (
    <>
      <nav
        className={navigationOpen ? "navigation navigation-open" : "navigation"}
      >
        <div className="navigationExit" onClick={() => setNavigationOpen(false)}></div>
        <div className="navigation-logo" onClick={() => handlePageRoute("/")}>
          <img src="/vegtaxFooterLogo.png" alt="company logo" />
          <div className="header-poweredby">
            <p>{t("powered")}</p>
            <img src="/veg-icon-new.svg" />
          </div>
        </div>
        <div>
          <ul className="navigation-list">
            <li
              className={
                location.pathname === "/tax-refund"
                  ? "navigation-list-text link-active"
                  : "navigation-list-text"
              } onClick={() => handlePageRoute("/tax-refund")}>
              {t("menu.taxrefund")}
            </li>
            <li
              className={
                location.pathname === "/howitworks"
                  ? "navigation-list-text link-active"
                  : "navigation-list-text"
              } onClick={() => handlePageRoute("/howitworks")}>
              {t("menu.howitworks")}
            </li>
            <li
              className={
                location.pathname === "/ourconcept"
                  ? "navigation-list-text link-active"
                  : "navigation-list-text"
              } onClick={() => handlePageRoute("/ourconcept")}>
              {t("menu.ourconcept")}
            </li>
            <li
              className={
                location.pathname === "/whoweare"
                  ? "navigation-list-text link-active"
                  : "navigation-list-text"
              } onClick={() => handlePageRoute("/whoweare")}>
              {t("menu.aboutus")}
            </li>
            <li
              className={
                location.pathname === "/contact"
                  ? "navigation-list-text link-active"
                  : "navigation-list-text"
              } onClick={() => handlePageRoute("/contact")}>
              {t("menu.contact")}
            </li>
          </ul>
        </div>
      </nav>

      <nav className="desktop-navigation">
        <div>
          <ul className="navigation-list">
            <li onClick={() => handlePageRoute("/tax-refund")}>
              <span
                className={
                  location.pathname === "/tax-refund"
                    ? "nav-li-text link-active"
                    : "nav-li-text"
                }
              >
                {t("menu.taxrefund")}
              </span>
            </li>
            <li onClick={() => handlePageRoute("/howitworks")}>
              <span
                className={
                  location.pathname === "/howitworks"
                    ? "nav-li-text link-active"
                    : "nav-li-text"
                }
              >
                {t("menu.howitworks")}
              </span>
            </li>
            <li onClick={() => handlePageRoute("/ourconcept")}>
              <span
                className={
                  location.pathname === "/ourconcept"
                    ? "nav-li-text link-active"
                    : "nav-li-text"
                }
              >
                {t("menu.ourconcept")}
              </span>
            </li>
            <li onClick={() => handlePageRoute("/whoweare")}>
              <span
                className={
                  location.pathname === "/whoweare"
                    ? "nav-li-text link-active"
                    : "nav-li-text"
                }
              >
                {t("menu.aboutus")}
              </span>
            </li>
            <li onClick={() => handlePageRoute("/contact")}>
              <span
                className={
                  location.pathname === "/contact"
                    ? "nav-li-text link-active"
                    : "nav-li-text"
                }
              >
                {t("menu.contact")}
              </span>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
