import React from "react";

import Contact from "../../components/contact";

function ContactPage(props) {
  return (
    <div className="page-container">
      <Contact />
    </div>
  );
}

export default ContactPage;
