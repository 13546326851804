import React, { useEffect } from "react";
import "./howitworks.css";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import Button from "../button";

function HowItWorks(props) {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-margin howItWorks-page">
      <img className="how-blob blob-right" src="how-blob.svg" alt="how-blob.svg" />
      <img className="how-blob blob-left" src="how-blob.svg" alt="how-blob.svg" />

      <div className="title-section-container">
        <h4 className="page-title">
          {t("how.titleStart")}
          <span className="logo-veg bold-logo-span">VEG</span>
          <span className="logo-tax bold-logo-span">TAX.</span>
          {t("how.titleEnd")}
        </h4>
        <p className="embarkWith-p description">{t("how.embarkWithVegtax")}</p>
      </div>

      <div className="boxes-container">
        <div className="step-box step-box1">
          <img className="step-box-icon" src="howItWorks-eligibility-icon.svg" alt="howItWorks-eligibility-icon.svg" />
          <div className="step-box-title-container">
            <p className="step-box-title-number">1. </p>
            <p className="step-box-title">
              {t("how.stepsSection.eligibilityTitleStart")}
              <span className="step-box-title-link" onClick={() => navigate("/ourconcept")}>{t("how.stepsSection.eligibilityTitleLink")}</span>
              {t("how.stepsSection.eligibilityTitleEnd")}
            </p>
          </div>
          <p className="step-box-description description">{t("how.stepsSection.eligibilityText")}</p>
        </div>

        <div className="boxes-link boxes-link1"></div>

        <div className="step-box step-box2">
          <img className="step-box-icon" src="howItWorks-submit-icon.svg" alt="howItWorks-submit-icon.svg" />
          <div className="step-box-title-container">
            <p className="step-box-title-number">2. </p>
            <p className="step-box-title">{t("how.stepsSection.submitTitle")}</p>
          </div>
          <p className="step-box-description description">{t("how.stepsSection.submitText")}</p>
        </div>

        <div className="boxes-link boxes-link2"></div>

        <div className="step-box step-box3">
          <img className="step-box-icon" src="howItWorks-pay-icon.svg" alt="howItWorks-pay-icon.svg" />
          <div className="step-box-title-container">
            <p className="step-box-title-number">3. </p>
            <p className="step-box-title">{t("how.stepsSection.payTitle")}</p>
          </div>
          <p className="step-box-description description">{t("how.stepsSection.payText")}</p>
        </div>
      </div>

      <div className="statistics-section-container">
        <img className="howItWorks-avatar" src="avatar-armsCrossed-turnLeft.png" alt="avatar-armsCrossed-turnLeft.png" />

        <div className="stats-box">
          <p className="stats-box-title">
            {t("how.statsSection.titleStart")}
            <span className="logo-veg-white medium-logo-span">VEG</span>
            <span className="logo-tax medium-logo-span">TAX.</span>
            {t("how.statsSection.titleEnd")}
          </p>

          <div className="stats-container">
            <div className="stat-container">
              <img className="how-stat-icon" src="steps-icon.svg" alt="steps-icon.svg" />
              <div className="stat-steps-text stat-text">
                <span className="stat-number">6 </span>
                <span>{t("steps")}</span>
              </div>
            </div>

            <div className="stat-container">
              <img className="how-stat-icon" src="time-icon.svg" alt="time-icon.svg" />
              <div className="stat-time-text stat-text">
                <span className="stat-number">15 </span>
                <span>{t("minutes")}</span>
              </div>
            </div>

            <div className="stat-container">
              <img className="how-stat-icon" src="money-icon.svg" alt="money-icon.svg" />
              <div className="stat-money-text stat-text">
                <span className="stat-number"> &lt; 100 </span>
                <span>CHF</span>
              </div>
            </div>
          </div>

          <Button text={t("how.statsSection.letsGo")}
            onClick={() => navigate("/tax-refund")}></Button>
        </div>
      </div>

    </div>
  );
}

export default HowItWorks;
