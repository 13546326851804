import React from "react";
import "./activityinputs.css";
import { useTranslation } from "react-i18next";
import ValidationError from "../validationError";

function ActivityInputs({
  activity,
  onChange,
  onDeleteActivity,
  index,
  activitiesValidationErrors,
  partner2ActivitiesValidationErrors,
}) {
  const [t, i18n] = useTranslation("global");

  return (
    <div className="activity-inputs-wrapper">
      <div className="activity-input-wrapper">
        <input
          className="activity-input"
          type="text"
          onChange={(e) => onChange(e, "name", activity.id)}
          value={activity?.name || ""}
        />
        <label className="select-label">
          {t("trefundstep2.fields.act")} {index + 1}
        </label>
      </div>
      <div className="activity-input-wrapper" style={{ position: "relative" }}>
        <ValidationError
          error={activitiesValidationErrors?.[activity.id]}
          message={t("numbers100")}
          topPosition={"-33px"}
        />
        <ValidationError
          error={partner2ActivitiesValidationErrors?.[activity.id]}
          message={t("numbers100")}
          topPosition={"-33px"}
        />
        <input
          className="activity-input"
          onChange={(e) => onChange(e, "taux", activity.id)}
          value={activity?.taux || ""}
          type="text"
        />
        <label className="select-label">{t("trefundstep2.fields.rate")}</label>
        <p className="percent-label">%</p>
      </div>
      {/* <button
        className="delete-dependent-child-button"
        onClick={() => onDeleteActivity(activity.id)}
      >
        <img src="/deleteCitcleButton.png" alt="delete dependent child" />
      </button> */}
    </div>
  );
}

export default ActivityInputs;
