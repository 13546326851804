import React from "react";
import WhoWeAre from "../../components/whoWeAre";

function WhoWeArePage() {
  return (
    <div className="page-container">
      <WhoWeAre />
    </div>
  );
}

export default WhoWeArePage;
