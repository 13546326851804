import React, { useEffect, useRef, useState } from "react";

import "./langswitcher.css";
import { useTranslation } from "react-i18next";

// CustomSelect component
const LanguageSwitcher = ({
  placeHolder,

  isMulti,
  isSearchable,
  onChange,
  align,
  transportId,
  label,
  mean,
}) => {
  // State variables using React hooks
  const [showMenu, setShowMenu] = useState(false); // Controls the visibility of the dropdown menu
  const [selectedValue, setSelectedValue] = useState(); // Stores the selected value(s)
  const [searchValue, setSearchValue] = useState(""); // Stores the value entered in the search input
  const searchRef = useRef(); // Reference to the search input element
  const inputRef = useRef(); // Reference to the custom select input element
  const [t, i18n] = useTranslation("global");
  const options = [
    { id: 1, value: "en", img: "enFlag.png" },
    { id: 2, value: "fr", img: "frFlag.png" },
  ];
  const handleChangeLanguage = (lang) => {
    localStorage.setItem("vegtaxLang", lang);
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    if (localStorage.getItem("vegtaxLang")) {
      setSelectedValue(localStorage.getItem("vegtaxLang"));
    } else {
      setSelectedValue("fr");
    }
  }, []);
  useEffect(() => {
    setSearchValue("");
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  useEffect(() => {}, []);

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  });

  const handleInputClick = (e) => {
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    return selectedValue?.value;
  };

  const removeOption = (option) => {
    return selectedValue.filter((o) => o.value !== option.value);
  };

  const onTagRemove = (e, option) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const onItemClick = (option) => {
    setSelectedValue(option.value);
    handleChangeLanguage(option.value);
  };

  const isSelected = (option) => {
    if (isMulti) {
      return selectedValue.filter((o) => o.value === option.value).length > 0;
    }

    if (!selectedValue) {
      return false;
    }

    return selectedValue.value === option.value;
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter(
      (option) =>
        option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  return (
    <div className="select-container-lsw">
      <div
        ref={inputRef}
        onClick={handleInputClick}
        className="dropdown-input-lsw"
      >
        {selectedValue && (
          <div
            className={
              showMenu ? "selected-value-lsw focused" : "selected-value-lsw "
            }
          >
            {/* <div className="select-label-lsw">{label}</div> */}
            <button className="triangle-lsw" type="button">
              <img
                src=" thinTriangle.png"
                className={!showMenu ? "" : "triangle-reverse"}
                alt="dropdown icon"
              />
            </button>
            <span
              style={{
                fontSize: "14px",
                fontWeight: "500",
                fontFamily: "Nunito Medium",
              }}
            >
              {selectedValue}
            </span>
          </div>
        )}
      </div>

      {showMenu && (
        <div className="options-container-lsw">
          <ul>
            {options.map((option) => (
              <li
                onClick={() => onItemClick(option)}
                key={option.value}
                className={`dropdown-item-lsw`}
              >
                <span>{option.value}</span>
                <img src={option.img} className="flag-icon" />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
