import React, { useEffect, useState, useRef } from "react";
import "./contact.css";
import { useTranslation } from "react-i18next";
import ValidationError from "../validationError";
import axios from "axios";
import url from "../../constants";
import Button from "../button";

function Contact(props) {
  //Animate floaty blobs when hover
  const contactContainer = useRef(null)
  useEffect(() => {
    let blobs = contactContainer.current.querySelectorAll(".blob-contact")
    blobs.forEach(blob => {
      blob.classList.add("popInBlob")
      setTimeout(() => {
        blob.classList.remove("popInBlob")
      }, 1000)

      blob.addEventListener('animationend', () => {
        blob.classList.remove('animated');
      });
      blob.addEventListener('mouseover', () => {
        blob.style.animationIterationCount = "infinite"
        blob.classList.add('animated')
      })
      blob.addEventListener('mouseleave', () => {
        blob.style.animationIterationCount = "1"
      })
    })
  }, [contactContainer])

  const [state, setState] = useState({});
  const [serverError, setServerError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [emailError, setEmailError] = useState();

  const [sendingRequest, setSendingRequest] = useState(false);
  const [personalInformationErrors, setPersonalInformationValidationErrors] =
    useState();
  const [t] = useTranslation("global");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const invalidatePersonalInformation = () => {
    setPersonalInformationValidationErrors(null);
  };

  useEffect(() => {
    let persRes = false;
    let emRes = false;

    const persInformationFields = ["name and lastname", "emai", "message"];

    persInformationFields.forEach((info) => {
      if (state?.[info]) {
        persRes = true;
      } else {
        persRes = false;
      }
    });

    if (persRes === true) {
      invalidatePersonalInformation();
    }

    if (isValidEmail(state.email)) {
      emRes = true;
    }

    if (emRes === true) {
      invalidateEmail();
    }
  }, [state]);

  const handleChangePersonalEnformation = (key, e) => {
    setState((state) => {
      return { ...state, [key]: e.target.value.trimStart() };
    });
  };

  const validatePersonalInformation = (form) => {
    let result = true;
    const requiredFields = ["name and lastname", "email", "message"];
    if (!form) {
      result = false;
      setPersonalInformationValidationErrors({
        "name and lastname": "name and lastname",
        email: "email",
        message: "message",
      });
    } else {
      requiredFields.forEach((key) => {
        if (!form[key]) {
          setPersonalInformationValidationErrors((errors) => {
            return { ...errors, [key]: key };
          });
          result = false;
        }
      });
    }

    return result;
  };

  const handleSubmit = (form) => {
    if (!validatePersonalInformation(form) || !validateEmail(form?.email)) {
      return;
    } else {
      sendContactForm();
    }
  };

  const sendContactForm = () => {
    try {
      setSendingRequest(true);
      axios
        .post(
          `${url}contact-form`,
          {
            nom: state?.["name and lastname"],
            email: state?.email,
            message: state.message,
          },
          { withCredentials: true }
        )
        .then((res) => {
          setServerError(false);
          setSendingRequest(false);
          setSuccess(true);
        })
        .catch((error) => {
          setSuccess(false);
          setServerError(error);
          setSendingRequest(false);
        });
    } catch (error) {
      setSuccess(false);
      setServerError(error);
      setSendingRequest(false);
    }
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const validateEmail = (email) => {
    let result = true;
    if (email && !isValidEmail(email)) {
      setEmailError("Email is invalid");
      result = false;
    }

    return result;
  };

  const invalidateEmail = () => {
    setEmailError(null);
  };

  return (
    <div ref={contactContainer} className="refund-desc">
      <img className="blob-contact screen-right" src="/contactPage-blob-lightBlue.svg" alt="contactPage-blob-lightBlue"></img>
      <img className="blob-contact screen-left" src="/contactPage-blob-darkBlue.svg" alt="contactPage-blob-darkBlue"></img>
      <div className="form-container fullwidth">
        <h4 className="title">{t("contactPage.title")}</h4>
        <p className="text-regular">{t("contactPage.needHelp")}</p>

        {!success ? (
          <>
            <div
              className="personal-information-form"
              style={{ position: "relative" }}
            >
              <ValidationError
                error={personalInformationErrors}
                topPosition={"-25px"}
                message={t("contactPage.reqfields")}
              />
              <ValidationError
                error={serverError}
                message={serverError?.message}
                topPosition={"-45px"}
              />
              <div className="regular-input-wrapper">
                <input
                  type="text"
                  className="activity-input"
                  onChange={(e) =>
                    handleChangePersonalEnformation("name and lastname", e)
                  }
                  value={state?.["name and lastname"] || ""}
                />
                <div className="select-label">
                  {t("contactPage.name")}
                </div>
              </div>
              <div
                className="regular-input-wrapper"
                style={{ position: "relative" }}
              >
                <ValidationError
                  error={emailError}
                  message={t("contactPage.emailError")}
                  topPosition={"-20px"}
                />
                <input
                  type="text"
                  className="activity-input"
                  onChange={(e) => handleChangePersonalEnformation("email", e)}
                  value={state?.email || ""}
                />
                <div className="select-label">{t("contactPage.email")}</div>
              </div>
              <div className="regular-input-wrapper ">
                <textarea
                  rows="4"
                  cols="50"
                  className="activity-input form-message"
                  onChange={(e) =>
                    handleChangePersonalEnformation("message", e)
                  }
                  value={state?.message || ""}
                />

                <div className="select-label" style={{ height: "10px" }}>
                  {t("contactPage.message")}
                </div>
              </div>
            </div>
            <div className="form-icon-container">
              <Button text={t("contactPage.send")}
                onClick={() => handleSubmit(state)}></Button>
              <div className="contact-info-container">
                <div className="phone-container contact-container">
                  <img className="contact-icon" src="/phone-icon.svg" alt="phone-icon"></img>
                  <a href="tel:0216348500" className="contact-text">021 634 85 00</a>
                </div>
                <div className="email-container contact-container">
                  <img className="contact-icon" src="/mail-icon.svg" alt="mail-icon"></img>
                  <a href="mailto:info@vegtax.ch" className="contact-text">info@vegtax.ch</a>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="message-sent">
            <h4>{t("messagesent")}</h4>
          </div>
        )}
      </div>


      {sendingRequest && <div className="page-overlay"></div>}
    </div>
  );
}

export default Contact;
