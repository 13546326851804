import React from "react";
import Privacy from "../../components/privacy";

function PrivacyPage(props) {
  return (
    <div className="page-container">
      <Privacy />
    </div>
  );
}

export default PrivacyPage;
