import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

function RulesAndConditions(props) {
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-margin">
      {/* <h4 className="page-title">{t("rulestitle")}</h4> */}
      <div className="privacyLogo">
        <img src="/vegConsLogo.png" />
      </div>
      <h5 className="priv-title">{t("rulestitle")}</h5>
      {i18n.language === "en" && (
        <div className="eligibility-descriptions ">
          <div className="privacy-block">
            <h4>1. INTRODUCTION</h4>
            <p className="privacy-description">
              This contract and these general terms and conditions of sale
              (hereinafter the "Conditions") govern all contractual relations
              between:
            </p>
            <p className="privacy-description">
              <p className="privacy-description">
                VEG Consulting Sàrl, Avenue du Théâtre 7, 1005 Lausanne,
                CHE-105.459.441, info@vegconsulting.ch, +41 21 634 85 00
                (hereinafter "Veg Consulting");
              </p>
              <p className="privacy-description">and</p>
              <p className="privacy-description">
                The natural person subscribing to the Online Service for the
                preparation of a tax return (hereinafter the "Client") offered
                on the www.vegtax.ch website (hereinafter the "Site").
              </p>
            </p>
          </div>
          <div className="privacy-block">
            <h4>2. SUBJECT MATTER AND CONCLUSION OF THE CONTRACT</h4>
            <p className="privacy-description">
              VEG Consulting offers an online service providing the Client with
              a service to process the preparation and transmission of its tax
              return on Swiss territory (hereinafter the "Service").
            </p>

            <p className="privacy-description">
              The Client wishes to use the Service in accordance with these
              Terms.
            </p>
            <p className="privacy-description">
              By ordering the Service according to the procedure mentioned below
              in point 3, the Client fully and entirely, without exception or
              reservation, accepts the Terms set out in this contract. These are
              also communicated to the Client during the validation process of
              each order, the registration of the order being subject to their
              acceptance. Any person subscribing to the Service declares,
              therefore, that he or she is fully aware of it and cannot deny
              this fact in the event of a dispute.
            </p>
            <p className="privacy-description">
              After ordering the Service on the Site, VEG Consulting will send a
              confirmation email to the Client at the email address provided.
              This email thus forms the contract of sale between VEG Consulting
              and the Client, governed by these Terms.
            </p>
          </div>
          <div className="privacy-block">
            <h4>3. CONDUCT OF THE SERVICE</h4>
            <h4 style={{ marginTop: "20px" }}>
              3.1 PERSONAL INFORMATION FORM AND ORDER VALIDATION
            </h4>
            <p className="privacy-description">
              VEG Consulting provides an online form on the Site allowing the
              Client to communicate the information necessary to enable VEG
              Consulting to offer the appropriate price for the requested
              Service. If the Client wishes to use the Service according to the
              price offered, it is required to integrate the necessary documents
              into the online form, to proceed with the validation of his order
              by clicking on "Conditions read and approved, Order and Payment"
              and to proceed with the payment of the price applicable to the
              requested Service.
            </p>
            <p className="privacy-description">
              A confirmation concerning the order, the price and the handling of
              the Client's file is promptly sent to the Client by Veg Consulting
              at the e-mail address provided. Once the documents are submitted
              and the price is paid in full, the documents are handed over to
              VEG Consulting.
            </p>
            <p className="privacy-description">
              The Customer has a right of withdrawal of 24 hours as mentioned in
              point 5 of these Conditions in the event of an error or if he
              simply wishes to cancel his order.
            </p>
            <h4 style={{ marginTop: "20px" }}>3.2. PRICE AND PAYMENT</h4>
            <p className="privacy-description">
              The applicable prices for the Service provided by VEG Consulting
              are indicated to the Client on the Site once the personal
              information form is completed. Prices vary according to the
              Client's request and personal circumstances (e.g. single, married,
              etc.). All prices are in CHF, including VAT if applicable.
            </p>
            <p className="privacy-description">
              Payment is due and is made in full at the time of validation of
              the order and therefore before the Service is rendered. Payment
              can be made by credit card via an online payment platform or by
              TWINT.
            </p>
            <p className="privacy-description">
              VEG Consulting reserves the right to change these Terms and the
              prices of the Service in the future. The offer of a price when
              submitting the Personal Information Form does not guarantee that
              the price and terms and conditions of sale will be the same when
              submitting the form or a subsequent order.
            </p>
            <h4 style={{ marginTop: "20px" }}>
              3.3 OBLIGATION AND RESPONSIBILITY FOR INFORMATION ON THE PART OF
              THE CUSTOMER
            </h4>
            <p className="privacy-description">
              Once the order has been validated and the price has been paid in
              full, the Client undertakes to provide all the documents and
              information required by VEG Consulting to process the mission
              entrusted to it by the Client and for the proper performance of
              the Service.
            </p>
            <p className="privacy-description">
              The Client fully acknowledges and accepts its responsibility to
              provide all required information and documents accurately. The
              Client acknowledges and agrees that VEG Consulting is under no
              obligation to verify or verify the accuracy of the information
              provided and accordingly, VEG Consulting disclaims any
              responsibility in the processing of the tax return arising from
              errors, omissions or inaccuracies, resulting directly or
              indirectly from the information provided by the Client.
            </p>
            <p className="privacy-description">
              The Client is aware and acknowledges that the provision of false,
              incomplete or outdated information may result in legal
              consequences, including but not limited to penalties, late payment
              interest and other penalties applicable under tax legislation and
              the Client accepts full responsibility for such penalties.
            </p>
            <h4 style={{ marginTop: "20px" }}>
              3.4 CANCELLATION OF THE SERVICE AND REFUND
            </h4>
            <p className="privacy-description">
              VEG Consulting reserves the right to unilaterally cancel the
              Service request entrusted by the Client in the event of
              impediment, reasonably assessed by Veg Consulting, of the
              performance of the Service under optimal conditions. This
              includes, but is not limited to, errors or delays in the delivery
              of Customer's information or unavailability of necessary
              resources.
            </p>
            <p className="privacy-description">
              VEG Consulting also reserves the right to cancel the request for
              Service if the information provided at the time of the price
              request does not correspond to the reality of the situation and
              the Client's need or in the event of Force Majeure as mentioned in
              point 3.5 below.
            </p>
            <p className="privacy-description">
              In this eventuality, VEG Consulting will reimburse the amount
              received no later than 14 days from the date on which the
              cancellation decision was taken.
            </p>
            <h4 style={{ marginTop: "20px" }}>
              3.5 VEG CONSULTING'S OBLIGATION AND DELIVERY OF THE SERVICE
            </h4>
            <p className="privacy-description">
              Subject to point 3.4 above, VEG Consulting undertakes to provide
              the Service requested by the Client with the utmost care,
              according to the information provided by the Client and to send
              the completed tax return to the Client for its review and, if
              applicable, its comments, within approximately 7 working days from
              the complete receipt of all necessary documents provided by the
              Client according to point 3.3 above.
            </p>
            <p className="privacy-description">
              This period may vary depending on the complexity of the tax return
              to be processed. In the event that the indicated deadline is
              exceeded, VEG Consulting undertakes to inform the Client as soon
              as possible of the reasons for the delay and the new estimated
              delivery date of the Service, taking into account the deadline for
              the finalization of the Client's tax return, insofar as the Client
              has made sufficient arrangements in advance to use the Service and
              all the necessary documents and information are provided in time.
            </p>
            <p className="privacy-description">
              The Client undertakes to examine its tax return upon receipt and
              to notify VEG Consulting within 10 days of receipt of any errors
              detected. Customer feedback notification must be in writing and
              include a detailed description of the identified error or any
              other feedback. If the Client does not respond within 10 days of
              receipt of the tax return, Veg Consulting may consider that the
              Client has no comments and that Veg Consulting may proceed with
              the filing of the tax return with the tax administration of the
              Client's canton of residence.
            </p>
            <p className="privacy-description">
              Once the tax return has been completed and filed with the tax
              authorities of the Client's canton of residence, VEG Consulting
              sends the Client a complete file including the confirmation of the
              filing, a copy of the tax return filed and the supporting
              documents declared and provided by the Client.
            </p>
            <p className="privacy-description">
              Veg Consulting is not liable for non-performance of the Service
              when it is the consequence of an impediment beyond its control
              ("Force Majeure"). VEG Consulting undertakes to notify the Client
              as soon as possible of the occurrence of such an event and to
              resume the performance of the Service or to cancel it as mentioned
              in point 3.4 above as soon as possible.
            </p>
            <h4 style={{ marginTop: "20px" }}>
              3.6 ERRORS IN THE EXECUTION OF THE TAX RETURN
            </h4>
            <p className="privacy-description">
              In the event of an error in the tax return, if this error is due
              to incorrect or incomplete information provided by the Client, VEG
              Consulting offers to provide assistance to correct the error and
              any costs associated with such correction will be borne by the
              Client. VEG Consulting will not be liable in any way for
              penalties, late payment interest or other financial consequences
              resulting from errors in the tax return due to the Client's
              liability. The assistance provided may be subject to additional
              invoicing which must be presented by a quote, accepted and paid by
              the Client before the error is corrected.
            </p>
            <p className="privacy-description">
              If, during the final assessment issued by the tax authorities,
              resulting errors that can be attributed to VEG Consulting are
              observed, VEG Consulting undertakes to reimburse the invoiced
              price in full as long as the financial damage is equal to or
              greater than the price initially paid.
            </p>
            <p className="privacy-description">
              Without prejudice to any other provision of these Terms that
              excludes or limits VEG Consulting's liability, VEG Consulting
              shall not be liable for damages caused to the Client in excess of
              100% of the price of the Service, to the extent permitted by
              applicable national law.
            </p>
          </div>
          <div className="privacy-block">
            <h4>4. PRIVACY AND DATA PROTECTION</h4>
            <p className="privacy-description">
              All data and information provided by the Client in the online
              personal information form and within the framework of this
              contract to provide the requested Service, are confidential and
              subject to tax secrecy. The data will only be used for the
              assignment that has been entrusted to VEG Consulting.
            </p>
            <p className="privacy-description">
              In the context of the price request, the validation of the order
              and the provision of the Service, VEG Consulting, collects and
              processes personal data in accordance with its Data Protection
              Statement, which we strongly encourage you to read and of which
              you can request a copy from us, and complies with the Swiss
              Federal Data Protection Act (FADP). The Data Protection
              Declaration is available on the Website at this ... . By ordering
              the Service, you confirm that you have read VEG Consulting's Data
              Protection Declaration.
            </p>
          </div>
          <div className="privacy-block">
            <h4>5. PERIOD OF WITHDRAWAL RIGHT</h4>
            <p className="privacy-description">
              The Client has a right of withdrawal of 24 hours from the date of
              payment for the Service. To cancel the Service, the Client must
              notify VEG Consulting of its decision to withdraw within this
              period by any means of written communication. In the event of a
              valid withdrawal within 24 hours, VEG Consulting will proceed to
              the full refund of the payment received for the cancelled Service,
              using the same means of payment used by the Client for the initial
              transaction.
            </p>
          </div>
          <div className="privacy-block">
            <h4>6. APPLICABLE LAW AND PLACE OF JURISDICTION</h4>
            <p className="privacy-description">
              These General Terms and Conditions of Sale are governed by Swiss
              law. Any dispute relating to these Terms or the contract shall be
              subject to the jurisdiction of the courts of Lausanne, canton of
              Vaud or the place of residence of the Client.
            </p>
          </div>
        </div>
      )}
      {i18n.language === "fr" && (
        <div className="eligibility-descriptions ">
          <div className="privacy-block">
            <h4>1. INTRODUCTION</h4>
            <p className="privacy-description">
              Ce contrat et les présentes conditions générales de vente
              (ci-après les « Conditions ») régissent l’ensemble des relations
              contractuelles entre :
            </p>
            <p className="privacy-description">
              <p className="privacy-description">
                VEG Consulting Sàrl, Avenue du Théâtre 7, 1005 Lausanne,
                CHE-105.459.441, info@vegconsulting.ch, +41 21 634 85 00
                (ci-après « Veg Consulting ») ;
              </p>
              <p className="privacy-description">et</p>
              <p className="privacy-description">
                La personne physique souscrivant le Service en ligne pour
                l’établissement d’une déclaration d’impôt (ci-après le « Client
                ») proposé sur le site internet www.vegtax.ch (ci-après le «
                Site »).
              </p>
            </p>
          </div>
          <div className="privacy-block">
            <h4>2. OBJET ET CONCLUSION DU CONTRAT</h4>
            <p className="privacy-description">
              VEG Consulting propose une prestation en ligne mettant à
              disposition du Client un service pour traiter l'établissement et
              la transmission de sa déclaration d'impôt sur le territoire Suisse
              (ci-après le « Service »).
            </p>

            <p className="privacy-description">
              Le Client souhaite faire recourt au Service selon les présentes
              Conditions.
            </p>
            <p className="privacy-description">
              Par la commande du Service selon la procédure mentionnée
              ci-dessous au point 3, le Client accepte pleinement et
              entièrement, sans exception ni réserve, les Conditions énoncées
              dans le présent contrat. Celles-ci sont, par ailleurs,
              communiquées au Client lors du processus de validation de chaque
              commande, l’enregistrement de la commande étant soumis à leur
              acceptation. Toute personne souscrivant au Service déclare, par
              conséquent, en avoir parfaitement connaissance et ne saurait nier
              ce fait en cas de litige.
            </p>
            <p className="privacy-description">
              Après la commande du Service sur le Site, VEG Consulting adresse
              un courriel de confirmation au Client, à l’adresse électronique
              fournie. Ce courriel forme ainsi le contrat de vente entre VEG
              Consulting et le Client, régi par les présentes Conditions.
            </p>
          </div>
          <div className="privacy-block">
            <h4>3. DÉROULEMENT DU SERVICE</h4>
            <h4 style={{ marginTop: "20px" }}>
              3.1 FORMULAIRE D'INFORMATION PERSONNELLE ET VALIDATION DE LA
              COMMANDE
            </h4>
            <p className="privacy-description">
              VEG Consulting met à disposition sur le Site un formulaire en
              ligne permettant au Client de communiquer l'information nécessaire
              pour permettre à VEG Consulting de proposer le prix adéquat pour
              le Service demandé. Si le Client souhaite recourir au Service
              selon le prix offert, il est requis d’intégrer les documents
              nécessaires au formulaire en ligne, de procéder à la validation de
              sa commande en cliquant sur « Conditions lues et approuvées,
              Commande et Paiement » et de procéder au paiement du prix
              applicable au Service demandé.
            </p>
            <p className="privacy-description">
              Une confirmation concernant la commande, le prix et la prise en
              charge du dossier du Client lui est rapidement envoyée par Veg
              Consulting à l’adresse électronique fournie. Une fois que les
              documents sont introduits et que le prix est payé dans son
              entièreté, les documents sont remis à VEG Consulting
            </p>
            <p className="privacy-description">
              Le Client a un droit de rétraction de 24 heures comme mentionné au
              point 5 de ces Conditions en cas d’erreur ou s’il souhaite
              simplement annuler sa commande.
            </p>
            <h4 style={{ marginTop: "20px" }}>3.2. PRIX ET PAIEMENT</h4>
            <p className="privacy-description">
              Les prix applicables pour le Service fourni par VEG Consulting
              sont indiqués au Client sur le Site une fois que le formulaire
              d’information personnelle est complété. Les prix varient selon la
              demande du Client et ses circonstances personnelles (par exemple :
              célibataire, marié etc.). Tous les tarifs sont exprimés en CHF,
              TVA incluse si applicable.
            </p>
            <p className="privacy-description">
              Le paiement est exigible et s’effectue dans sa totalité au moment
              de la validation de la commande et de ce fait avant que le Service
              ne soit rendu. Le paiement peut être fait par carte de crédit via
              une plateforme de paiement en ligne ou par TWINT.
            </p>
            <p className="privacy-description">
              VEG Consulting se réserve le droit de modifier ces Conditions et
              les prix du Service à l’avenir. L’offre d’un prix lors de la
              soumission du formulaire d’information personnelle ne garantit pas
              que le prix et les conditions générales de vente soient les même
              lors d’une soumission de formulaire ou d’une commande ultérieure.
            </p>
            <h4 style={{ marginTop: "20px" }}>
              3.3 OBLIGATION ET RESPONSABILITE DE L’INFORMATION À LA CHARGE DU
              CLIENT
            </h4>
            <p className="privacy-description">
              Une fois la commande validée et le prix entièrement payé, le
              Client s’engage à fournir tous les documents et informations
              requis par VEG Consulting pour traiter la mission que le Client
              lui confie et pour la bonne exécution du Service.
            </p>
            <p className="privacy-description">
              Le Client reconnaît et accepte pleinement sa responsabilité de
              fournir toutes les informations et tous les documents requis de
              manière exacte. Le Client reconnaît et accepte que VEG Consulting
              ne soit pas tenue de vérifier ou de contrôler l’exactitude des
              informations fournies et en conséquence, VEG Consulting décline
              toute responsabilité dans le traitement de la déclaration d’impôt
              découlant d’erreurs, d’omissions ou d’inexactitudes, résultants
              directement ou indirectement de l’information fournie par le
              Client.
            </p>
            <p className="privacy-description">
              Le Client est conscient et reconnaît que la fourniture
              d'informations fausses, incomplètes ou obsolètes peut entraîner
              des conséquences légales, y compris, mais sans s'y limiter, des
              pénalités, des intérêts de retard et d'autres sanctions
              applicables selon la législation fiscale et le Client accepte
              entièrement la responsabilité de ces sanctions.
            </p>
            <h4 style={{ marginTop: "20px" }}>
              3.4 ANNULATION DE LA PRESTATION ET REMBOURSEMENT
            </h4>
            <p className="privacy-description">
              VEG Consulting se réserve le droit d’annuler unilatéralement la
              demande de Service confiée par le Client en cas d’empêchement,
              évalué de façon raisonnable par Veg Consulting, de la réalisation
              du Service dans les conditions optimales. Cela inclus, mais n’est
              pas limité à, des erreurs ou des délais dans la remise des
              informations du Client ou de l’indisponibilité des ressources
              nécessaires.
            </p>
            <p className="privacy-description">
              VEG Consulting se réserve également le droit d’annuler la demande
              de Service si l’information fournie au moment de la demande de
              prix ne correspond pas à la réalité de la situation et au besoin
              du Client ou en cas de Force Majeure comme mentionné au point 3.5
              ci-dessous.
            </p>
            <p className="privacy-description">
              Dans cette éventualité VEG Consulting procède au remboursement de
              la somme perçue et ceci au plus tard dans les 14 jours à compter
              de la date à laquelle la décision d’annulation a été prise.
            </p>
            <h4 style={{ marginTop: "20px" }}>
              3.5 OBLIGATION DE VEG CONSULTING ET LIVRAISON DU SERVICE
            </h4>
            <p className="privacy-description">
              Sous réserve du point 3.4 ci-dessus, VEG Consulting s'engage à
              fournir le Service demandé par le Client avec le plus grand soin,
              selon les informations fournies par le Client et à envoyer la
              déclaration d’impôt complétée au Client pour son examen et le cas
              échéant ses commentaires, dans un délai d’environ 7 jours
              ouvrables à compter de la réception complète de tous les documents
              nécessaires fournis par le Client selon le point 3.3 ci-dessus.
            </p>
            <p className="privacy-description">
              Ce délai peut être amené à varier en fonction de la complexité de
              la déclaration d'impôt à traiter. En cas de dépassement du délai
              indiqué, VEG Consulting s'engage à informer le Client dès que
              possible des raisons du retard et de la nouvelle date estimée de
              livraison du Service tenant compte du délai buttoir pour la
              finalisation de la déclaration d’impôt du Client, dans la mesure
              où le Client s’est pris suffisamment à l’avance pour recourir au
              Service et tous les documents et informations nécessaires sont
              fournis dans les temps.
            </p>
            <p className="privacy-description">
              Le Client s'engage à examiner sa déclaration d'impôt dès réception
              et à notifier à VEG Consulting dans un délai de 10 jours à compter
              de la réception des éventuelles erreurs détectées. La notification
              de commentaires du Client doit être faite par écrit et inclure une
              description détaillée de l'erreur identifiée ou de tout autre
              commentaire. Sans retour du Client dans les 10 jours à compter de
              la réception de la déclaration d’impôt, Veg Consulting peut
              estimer que le Client n’a pas de commentaires et que Veg
              Consulting peut procéder au dépôt de la déclaration d’impôt auprès
              de l’administration fiscale du canton de résidence du Client.
            </p>
            <p className="privacy-description">
              Une fois la déclaration d’impôt terminée et déposée auprès de
              l’administration fiscale du canton de résidence du Client, VEG
              Consulting envoie au Client un dossier complet comprenant la
              confirmation du dépôt, une copie de la déclaration d’impôt déposée
              et les pièces justificatives déclarées et fournies par le Client.
            </p>
            <p className="privacy-description">
              La responsabilité de Veg Consulting pour non-exécution du Service
              n’est pas engagée lorsqu’elle est la conséquence d’un empêchement
              indépendant de sa volonté («Force Majeure»). VEG Consulting
              s’engage à communiquer le plus tôt possible au Client la
              survenance d’un tel évènement et à reprendre l’exécution du
              Service ou à l’annuler comme mentionné au point 3.4 ci-dessus
              aussi tôt que possible.
            </p>
            <h4 style={{ marginTop: "20px" }}>
              3.6 ERREURS DANS L’EXECUTION DE LA DECLARATION D’IMPOT
            </h4>
            <p className="privacy-description">
              Dans le cas d’une erreur dans la déclaration d’impôt, si cette
              erreur est due à des informations incorrectes ou incomplètes
              fournies par le Client, VEG Consulting propose de fournir une
              assistance pour corriger l'erreur et les frais éventuels associés
              à cette correction seront à la charge du Client. VEG Consulting ne
              sera en aucun cas responsable des pénalités, intérêts de retard ou
              autres conséquences financières résultant d'erreurs dans la
              déclaration d'impôt due à la responsabilité du Client.
              L’assistance fournie peut faire l’objet d’une facturation
              complémentaire qui devra être présentée par un devis, acceptée et
              payée par le Client avant la correction de l’erreur.
            </p>
            <p className="privacy-description">
              Si lors de la taxation définitive émise par les autorités
              fiscales, des erreurs résultantes et pouvant être attribuées à VEG
              Consulting sont observées, VEG Consulting s’engage à rembourser en
              totalité le prix facturé pour autant que le dommage financier soit
              égal ou supérieur au prix payé initialement.
            </p>
            <p className="privacy-description">
              Sans préjudice de toute autre disposition des présentes Conditions
              écartant ou limitant la responsabilité de VEG Consulting, VEG
              Consulting ne pourra être tenue responsable des dommages causés au
              Client supérieurs à 100% du prix du Service, dans la mesure où le
              droit national applicable l’autorise.
            </p>
          </div>
          <div className="privacy-block">
            <h4>4. CONFIDENTIALITÉ ET PROTECTION DES DONNÉES</h4>
            <p className="privacy-description">
              Toutes les données et informations fournies par le Client dans le
              formulaire en ligne d’information personnelle et dans la cadre du
              présent contrat pour fournir le Service demandé, sont
              confidentielles et soumis au secret fiscal. Les données seront
              uniquement utilisées pour la mission qui a été confiée à VEG
              Consulting.
            </p>
            <p className="privacy-description">
              Dans le cadre de la demande de prix, de la validation de la
              commande et de la fourniture du Service, VEG Consulting, recueille
              et traite des données personnelles conformément à sa Déclaration
              de Protection des Données que nous vous encourageons vivement à
              consulter et dont vous pouvez nous demander une copie, et est
              conforme à la loi fédérale Suisse en matière de protection des
              données (LPD). La Déclaration de Protection des Données est
              accessible sur le Site à ce … . Par la commande du Service, vous
              confirmez avoir pris connaissance de la Déclaration de Protection
              des Données de Veg Consulting.
            </p>
          </div>
          <div className="privacy-block">
            <h4>5. DELAI DROIT DE RETRACTATION</h4>
            <p className="privacy-description">
              Le Client dispose d'un droit de rétractation de 24 heures à
              compter de la date du paiement du Service. Pour annuler le
              Service, le Client doit notifier à VEG Consulting sa décision de
              se rétracter dans ce délai par tout moyen de communication écrit.
              En cas de rétractation valide dans les 24 heures, VEG Consulting
              procédera au remboursement total du paiement reçu pour le Service
              annulé, en utilisant le même moyen de paiement que celui employé
              par le Client pour la transaction initiale.
            </p>
          </div>
          <div className="privacy-block">
            <h4>6. DROIT APPLICABLE ET FOR JURIDIQUE</h4>
            <p className="privacy-description">
              Les présentes conditions générales de vente sont régies par le
              droit suisse. Tout litige relatif à ces Conditions ou au contrat
              sera soumis à la compétence des tribunaux de Lausanne, canton de
              Vaud ou du lieu de domicile du Client.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default RulesAndConditions;
